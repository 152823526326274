import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import api from "../../services/api";
import Modal from "../../components/Modal";
import Breadcrumbs from "../../components/Breadcrumbs";

const columns = [
    { name: 'name', displayText: 'Nombre' },
    { name: 'acronym', displayText: 'Acrónimo' },
    { name: 'symbol', displayText: 'Símbolo' }
]

export default function CurrenciesPage() {

    const [loading, setLoading] = useState(true)
    const [currencies, setCurrencies] = useState([]);
    const [open, setOpen] = useState(false);
    const [idCurrency, setIdCurrency] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [currency, setCurrency] = useState({
        name: "",
        acronym: "",
        symbol: "",
    })
    const [errors, setErrors] = useState({
        name: '',
        acronym: "",
        symbol: "",
    });

    const handleChange = (field, value) => setCurrency((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const clearFields = () => {
        handleChange("name", "");
        handleChange("acronym", "");
        handleChange("symbol", "");
    }

    const fetchCurrency = async (id) => {
        await api.currencies.getOne(id).then((response) => {
            setCurrency({
                id: response.id,
                name: response.name,
                acronym: response.acronym,
                symbol: response.symbol,
            })
        })
    }

    const deleteCurrency = async (id) => {
        await api.currencies.delete(id).catch(response => { console.log(response) }).then(() => (fetchCurrencies(), setOpenConfirmation(false), clearFields()))
    }

    const openConfirmationModal = (id) => {
        setIdCurrency(id)
        setOpenConfirmation(true)
    }

    const fetchCurrencies = async () => {
        await api.currencies.getMulti().then((response) => {
            setCurrencies(response.map(({ name, acronym, symbol, id, }) => {
                return ({
                    name: name, id: id, acronym: acronym, symbol: symbol
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const handleSubmit = () => {
        let error = false
        if (idCurrency) {
            api.currencies.update(idCurrency, currency).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchCurrencies(), setOpen(false), clearFields()))
        } else {
            if (!currency.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!currency.acronym) {
                handleError('acronym', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('acronym', '')
            }
            if (!currency.symbol) {
                handleError('symbol', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('symbol', '')
            }
            api.currencies.create(currency).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchCurrencies(), setOpen(false), clearFields()))
        }
    };

    const pages = [
        { name: 'Configuraciones', href: '/configurations', current: false },
        { name: 'Monedas', href: '#', current: true }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCurrencies();
    }, [])

    const editCurrency = (id) => {
        setIdCurrency(id)
        fetchCurrency(id)
        setOpen(true)
    }

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={currency.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre"
                    />
                </div>
                <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="acronym"
                        id="acronym"
                        value={currency.acronym}
                        onChange={(event) => handleChange('acronym', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Acrónimo (tres letras)"
                    />
                </div>
                <div className="flex relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="symbol"
                        id="symbol"
                        value={currency.symbol}
                        onChange={(event) => handleChange('symbol', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Símbolo"
                    />
                </div>
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Agregar
            </button>
        </>
    )

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar esta moneda?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteCurrency(idCurrency)}
            >
                Aceptar
            </button>
        </div>
    )

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Nueva moneda" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar moneda" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="configurations" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Monedas</h1>
                        </div>
                        <button
                            onClick={() => setOpen(true)}
                            type="button"
                            className="inline-flex sm:mr-20 mr-2 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                        >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Crear nueva moneda
                        </button>
                    </div>
                    <div className="mt-10 sm:px-20 px-2 sm:w-3/4">
                        <Table editRow={editCurrency} deleteRow={openConfirmationModal} columns={columns} rows={currencies} />
                    </div>
                </>}
        </div >
    );
}
