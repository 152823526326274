import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../services/api";
import { PlusIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/outline";

export default function SelectionPage() {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="min-h-screen select-none h-full flex flex-col bg-gray-50">
            <Header current="clients" />
            <div className="flex py-6 bg-white justify-between">
                <div className="flex flex-col sm:px-24 space-y-2">
                    <h1 className="font-bold text-3xl ml-4">Clientes</h1>
                </div>
            </div>
            <div className="flex flex-col gap-y-4 sm:flex-row align-middle justify-center gap-x-40 mt-20 px-20">
                <button onClick={() => navigate('/clients')} className="bg-white px-12 py-10 overflow-hidden shadow rounded-lg">
                    <div className="sm:px-8 py-10 sm:p-6">
                        <div className="flex flex-col space-y-4">
                            <UserGroupIcon className="h-20 w-20 stroke-1 text-gray-500 mx-auto" />
                            <div className="inline-flex items-center h-fit px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900">
                                Ver lista de clientes
                            </div>
                        </div>
                    </div>
                </button>
                <button onClick={() => navigate('/clients/new')} className="bg-white px-12 py-10 overflow-hidden shadow rounded-lg">
                    <div className="sm:px-8 py-10 sm:p-6">
                        <div className="flex flex-col space-y-4">
                            <PlusIcon className="h-20 w-20 stroke-1 text-gray-500 mx-auto" />
                            <div className="inline-flex h-fit items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900">
                                Agregar nueva familia
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div >
    );
}
