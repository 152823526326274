import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Dialog } from "@headlessui/react";
import api from "../../services/api";
import GridList from "../../components/GridList";
import { BriefcaseIcon, CashIcon, EyeIcon, CurrencyDollarIcon, DocumentTextIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import { PlusIcon, ExclamationIcon, ViewListIcon, TrendingUpIcon, } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumbs';
import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import TextInput from "../../components/TextInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import DateInput from "../../components/DateInput";
import Modal from "../../components/Modal";
import Steps from "../../components/Steps";

const columns = [
    { name: 'name', displayText: 'Nombre' },
    { name: 'namecode', displayText: 'Código de nombre' },
]

const pages = [
    { name: 'Clientes', href: '/clients', current: false },
    { name: 'Panel de control', href: '#', current: true }
]

const COLORS = ['#7C4DFF', '#0091EA', '#69F0AE', '#FF8042'];
const RADIAN = Math.PI / 180;

export default function ClientDashboard() {

    const today = new Date().toISOString().substring(0, 10)
    const [loading, setLoading] = useState(true)
    const [loadingMember, setLoadingMember] = useState(true)
    const [client, setClient] = useState([]);
    const [assetCategories, setAssetCategories] = useState(null);
    const [data, setData] = useState([]);
    const { idClient } = useParams();
    const [visible, setVisible] = useState(false)
    const navigate = useNavigate();
    const [isStaff, setIsStaff] = useState(localStorage.getItem('TFOaccessToken') ? true : false)
    const [actions, setActions] = useState([])
    const [member, setMember] = useState(null)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [openDetails, setOpenDetails] = useState(false)
    const [user, setUser] = useState(null)
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        clientPassword: '',
        members: '',
        govId: '',
        firstName: '',
        lastName: '',
        email: '',
        birthDate: '',
    });
    const [steps, setSteps] = useState([
        { id: '01', name: 'Ingresar número de cédula', status: 'current' },
        { id: '02', name: 'Verificar y agregar', status: 'upcoming' },
    ])

    const handleChange = (field, value) => setMember((prev) => ({ ...prev, [field]: value }));
    const handleMember = (field, value) => setMember((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const searchMember = async () => {
        await api.familyMembers.getMulti(null, null, null, null, { gov_id: member.govId }).then((response) => {
            if (response.length > 0) {
                setMember(response[0]);
            }
        }).then(() => setLoadingMember(false)).catch((error) => (console.log(error), setLoadingMember(false)))
    }

    const fetchClient = () => {
        api.clients.getOne(idClient).then((response) => {
            setClient(response);
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        api.clients.getOne(idClient).then((response) => {
            setClient(response);
        })

        api.users.getMe().then((response) => {
            setUser(response)
        })

        api.generalReport.getOne(idClient).then((response) => {
            if (response.properties.percentage > 0) {
                setData([{ name: 'Inmuebles', acronym: 'Inmuebles', value: response.properties.percentage, usdAmount: response.properties.usdConversion, pygAmount: response.properties.pygConversion },
                ...response.currencies.map((currency) => {
                    return (
                        { name: currency.name, acronym: currency.acronym, value: currency.percentage, pygAmount: currency.pygConversion, usdAmount: currency.usdConversion }
                    )
                })
                ]);
            } else {
                setData([
                    ...response.currencies.map((currency) => {
                        return (
                            { name: currency.name, acronym: currency.acronym, value: currency.percentage, pygAmount: currency.pygConversion, usdAmount: currency.usdConversion }
                        )
                    })
                ]);
            }
        }).catch((error) => console.log(error))
        api.assetCategories.getMulti().then((response) => {
            setAssetCategories(response);
        })
        setLoading(false);

    }, [])

    const submitChangePassword = async () => {
        let email = client.members.find((element) => element.isHead === true).email
        await api.resetClientPassword.create({ clientId: client.id, userId: user.id, email: email })
            .then((response) => {
                console.log(response)
                setOpenChangePassword(false)
            })
            .catch((error) => console.log(error))
    }

    const clearMember = () => {
        handleMember("firstName", "")
        handleMember("lastName", "")
        handleMember("govId", "")
        handleMember("email", "")
        handleMember("birthDate", today)
        handleMember("isHead", false)
        handleMember("cellphone", "")
        handleMember("inheritancePercentage", "")
        handleMember("relation", "")
        handleMember("idx", null)
        handleMember("id", null)
        setLoadingMember(true)
        handleError("firstName", "")
        handleError("lastName", "")
        handleError("govId", "")
        handleError("email", "")
        handleError("birthDate", "")
    }

    const goNext = () => {
        if (member.govId !== "") {
            searchMember();
            setSteps([
                { id: '01', name: 'Ingresar número de cédula', status: 'complete' },
                { id: '02', name: 'Verificar y agregar', status: 'current' }])
        } else {
            handleError('govId', 'Este campo es obligatorio')
        }
    }

    const addMember = () => {
        let error = false;
        if (!member.firstName) {
            handleError('firstName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('firstName', '')
        }
        if (!member.lastName) {
            handleError('lastName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('lastName', '')
        }
        if (!member.email) {
            handleError('email', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('email', '')
        }
        if (!member.birthDate) {
            handleError('birthDate', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('birthDate', '')
        }
        if (!error) {
            if (member.id) {
                api.memberDetails.create({ familyMember: member.id, client: idClient, relation: member.relation ? member.relation : '', isHead: member.isHead ? member.isHead : false }).then(() => api.clients.getOne(idClient).then((response) => {
                    setClient(response);
                })
                )
                setOpen(false);
                setSteps([
                    { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                    { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]);
                clearMember()
            } else {
                api.familyMembers.create(member).then((response) => {
                    api.memberDetails.create({ familyMember: response.id, client: idClient, relation: member.relation ? member.relation : '', isHead: member.isHead ? member.isHead : false }).then(() => api.clients.getOne(idClient).then((response) => {
                        setClient(response);
                    }))
                })
                setOpen(false);
                setSteps([
                    { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                    { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]);
                clearMember()

            }
        }
    }


    useEffect(() => {
        if (assetCategories) {
            setActions([
                {
                    title: 'Efectivo y Productos Financieros',
                    href: `/clients/${idClient}/assets/new/1`,
                    icon: CashIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'EPF').description
                },
                {
                    title: 'Pagarés y Bonos de Renta Fija',
                    href: `/clients/${idClient}/assets/new/2`,
                    icon: DocumentTextIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'BRF').description
                },
                {
                    title: 'Participación en Empresas',
                    href: `/clients/${idClient}/assets/new/3`,
                    icon: BriefcaseIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'PEE').description
                },
                {
                    title: 'Productos Estructurados',
                    href: `/clients/${idClient}/assets/new/4`,
                    icon: TrendingUpIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'PES').description
                },
                {
                    title: 'Bienes Raíces',
                    href: `/clients/${idClient}/assets/new/5`,
                    icon: OfficeBuildingIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'BRS').description
                },
                {
                    title: 'Activos Alternativos Directos',
                    href: `/clients/${idClient}/assets/new/6`,
                    icon: CurrencyDollarIcon,
                    iconForeground: 'text-blue-700',
                    iconBackground: 'bg-blue-50',
                    description: assetCategories.find((element) => element.abbreviation === 'AAD').description
                },
            ])
        }
    }, [assetCategories])

    let renderLabel = function (entry) {
        return entry.acronym + ' ' + entry.value + '%';
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="px-4 py-2 bg-white border border-gray-300">
                    <p className="label">{`${payload[0].name} : ${payload[0].value} %`}</p>
                </div>
            );
        }

        return null;
    };

    const editMember = async () => {
        let error = false;
        if (!member.firstName) {
            handleError('firstName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('firstName', '')
        }
        if (!member.lastName) {
            handleError('lastName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('lastName', '')
        }
        if (!member.email) {
            handleError('email', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('email', '')
        }
        if (!member.birthDate) {
            handleError('birthDate', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('birthDate', '')
        }
        if (!error) {
            await api.familyMembers.update(member.familyMemberId, member).catch((error) => console.log(error))
            await api.memberDetails.update(member.detailId, { familyMember: member.familyMemberId, client: client.id, relation: member.relation, isHead: member.isHead }).then((response) => {
                fetchClient();
                setOpenDetails(false);
            }).catch((error) => console.log(error))

        }
    }

    const GeneralReportChart = () => {
        return (
            <PieChart width={500} height={240}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label={renderLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const changepassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Cambiar contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <p className="text-md leading-6 font-medium text-gray-900">
                            Se enviará un correo al cabeza de familia para el cambio de contraseña.
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        submitChangePassword()
                    }
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenChangePassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )


    const content = (
        <>
            <p className="mt-4 font-semibold text-left text-blue-600">Detalles</p>
            <div className="mt-4 grid grid-cols-2 gap-x-4 w-3/4" >
                <TextInput handleChange={handleChange} name="firstName" value={member?.firstName} containerClassName="text-left" label="Nombres" placeholder="Nombres" />
                <TextInput handleChange={handleChange} name="lastName" value={member?.lastName} containerClassName="text-left" label="Apellidos" placeholder="Apellidos" />
                <TextInput handleChange={handleChange} name="govId" value={member?.govId} containerClassName="text-left" label="C.I." placeholder="Nombres" />
                <DateInput handleChange={handleChange} name="birthDate" defaultValue={member?.birthDate} containerClassName="text-left" label="Fecha de nacimiento" />
                <TextInput handleChange={handleChange} name="email" value={member?.email} containerClassName="text-left" label="Correo electrónico" />
                <TextInput handleChange={handleChange} name="cellphone" value={member?.cellphone} containerClassName="text-left" label="Número de teléfono" />
                <TextInput handleChange={handleChange} name="relation" value={member?.relation} containerClassName="text-left" label="Vínculo familiar" placeholder="Vínculo" />
                <InlineCheckbox handleChange={handleChange} containerClassName="mt-3" name="isHead" value={member?.isHead} label="Es cabeza de familia" />
                <div></div>
                <button onClick={() => editMember()} className="inline-flex  items-center mt-4 w-fit h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900">
                    Aceptar
                </button>
            </div>
        </>
    )

    const fullContent = (
        <>
            <Steps steps={steps} />
            {steps[0].status === "current" ?
                <>
                    <TextInput handleChange={handleMember} value={member?.govId} name="govId" containerClassName="mt-4 w-1/3 text-left" label="Ingresa el número de cédula" placeholder="C.I." />
                    {errors.govId !== "" ?
                        <div className="text-left mt-2 text-sm text-red-600">
                            {errors.govId}
                        </div>
                        : null}
                    <button onClick={() => goNext()}
                        type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm">
                        Siguiente
                    </button>
                </>
                : null}
            {steps[1].status === "current" ?
                <>
                    {loadingMember ?
                        <div className="flex h-60 items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <>
                            <p className="mt-4 font-semibold text-left text-blue-600">Crear nuevo integrante</p>
                            <p className="font-semibold text-left text-gray-600">C.I. {member.govId}</p>
                            <div className="mt-4 grid grid-cols-2 gap-x-4 w-3/4" >
                                <TextInput errorMessage={errors.firstName} name="firstName" handleChange={handleMember} value={member.firstName} containerClassName="text-left" label="Nombres" placeholder="Nombres" />
                                <TextInput errorMessage={errors.lastName} name="lastName" handleChange={handleMember} value={member.lastName} containerClassName="text-left" label="Apellidos" placeholder="Apellidos" />
                                <TextInput errorMessage={errors.email} name="email" handleChange={handleMember} value={member.email} containerClassName="text-left" label="Correo electrónico" />
                                <DateInput errorMessage={errors.birthDate} name="birthDate" handleChange={handleMember} defaultValue={member.birthDate} containerClassName="text-left" label="Fecha de nacimiento" />
                                <TextInput name="relation" handleChange={handleMember} value={member.relation} containerClassName="text-left" label="Vínculo familiar" placeholder="Vínculo" />
                                <TextInput name="cellphone" handleChange={handleMember} value={member.cellphone} containerClassName="text-left" label="Número de teléfono" />
                                <InlineCheckbox handleChange={handleMember} name="isHead" value={member.isHead} label="Es cabeza de familia" />
                            </div>
                            <button onClick={() => addMember()} type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm">
                                Agregar
                            </button>
                        </>
                    }
                </>
                : null}
        </>
    )


    return (
        <div className="min-h-screen h-full select-none bg-gray-50">
            <Modal size="sm:max-w-2xl" open={open} onClose={() => (setOpen(false), clearMember(), setSteps([
                { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]))} content={fullContent} title="Nuevo integrante" />
            <Modal content={changepassword} onClose={() => (setOpenChangePassword(false))} open={openChangePassword} setOpen={setOpenChangePassword} />
            <Modal content={content} onClose={() => (setOpenDetails(false), setMember(false))} open={openDetails} setOpen={setOpenDetails} />
            <Header current="clients" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>
                    <div className="flex py-6 bg-white w-full justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2 w-full">
                            {isStaff ? <Breadcrumbs pages={pages} /> : null}
                            <div className="flex justify-between">
                                <div className="flex flex-col">
                                    <h1 className="font-bold text-3xl ml-4">Reporte Patrimonial</h1>
                                    <h1 className="font-bold text-3xl ml-4">{client.name}</h1>
                                </div>
                                <div className="flex flex-col space-y-4 sm:space-y-0 mr-4 sm:mr-0 sm:flex-row gap-x-4">
                                    <button type="button" onClick={() => setOpenChangePassword(true)} className="inline-flex items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200">
                                        Cambiar contraseña
                                    </button>
                                    <button onClick={() => navigate(`/clients/${idClient}/assets`)} className="inline-flex h-fit items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                        <ViewListIcon className="h-6 w-6 mr-2" />
                                        <p className="text-md leading-6 font-semibold text-gray-700">Ver activos cargados</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 gap-x-4 mt-10 sm:px-24 w-full min-h-fit">
                        <div className="bg-white overflow-hidden shadow rounded-lg w-auto mx-2 sm:w-[40%]">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="flex justify-between">
                                    <p className="text-xl font-semibold text-gray-700">Situación global</p>
                                    <button onClick={() => navigate(`/clients/${idClient}/reports`)} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        Ver reportes
                                    </button>
                                </div>
                                <div className="flex justify-center mt-4 mr-10">
                                    <GeneralReportChart />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white overflow-hidden shadow flex flex-col justify-between rounded-lg w-auto mx-2 sm:w-[60%]">
                            <div className="px-4 py-5 sm:p-6">
                                {client.members ? client.members.map((member) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="font-medium text-sm text-gray-600">{member.relation}</p>
                                            <div className="flex justify-between">
                                                <p className="font-semibold text-gray-700">{member.firstName + ' ' + member.lastName}</p>
                                                <p className="font-medium text-gray-600">C.I. {member.govId}</p>
                                                <p className="font-semibold text-gray-700">{member.email}</p>
                                                <button type="button" onClick={() => (setMember(member), setOpenDetails(true))} className="font-semibold text-blue-600">
                                                    Ver detalles
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                ) : null}
                            </div>
                            <div className="flex justify-end bottom-10 mr-10 mb-10">
                                <button onClick={() => setOpen(true)} className="inline-flex w-fit justify-center items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200">
                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                    Agregar integrante
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-10 mx-2 sm:px-24 pb-8">
                        <div className="bg-white space-y-4 overflow-hidden shadow rounded-lg">
                            <p className="text-xl font-semibold text-gray-700 mt-8 ml-4">Seleccionar activo a cargar</p>
                            <GridList actions={actions} />
                        </div>
                    </div>
                </>}
        </div >
    );
}
