import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import api from "../../services/api";
import { PlusIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import DateInput from "../../components/DateInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import Steps from "../../components/Steps";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

const columns = [
    { name: 'name', displayText: 'Nombre' },
    { name: 'namecode', displayText: 'Código de nombre' },
]

const pages = [
    { name: `Inicio`, href: `/home`, current: false },
    { name: 'Nueva familia', href: '#', current: true }
]

export default function ClientPage() {

    const today = new Date().toISOString().substring(0, 10)
    const [loading, setLoading] = useState(false)
    const [loadingMember, setLoadingMember] = useState(true)
    const [input, setInput] = useState();
    const [index, setIndex] = useState(null);
    const navigate = useNavigate();
    const [idClient, setIdClient] = useState(null);
    const [open, setOpen] = useState(false);
    const [client, setClient] = useState({
        name: '',
        namecode: '',
        members: []
    });
    const [member, setMember] = useState({
        relation: '',
        inheritancePercentage: '',
        isHead: false,
        firstName: '',
        lastName: '',
        govId: '',
        email: '',
        birthDate: today,
        cellphone: ''
    })
    const [errors, setErrors] = useState({
        name: '',
        clientPassword: '',
        members: '',
        govId: '',
        firstName: '',
        lastName: '',
        email: '',
        birthDate: '',
        call: '',
    });
    const [members, setMembers] = useState([]);

    const handleChange = (field, value) => setClient((prev) => ({ ...prev, [field]: value }));
    const handleMember = (field, value) => setMember((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const [steps, setSteps] = useState([
        { id: '01', name: 'Ingresar número de cédula', status: 'current' },
        { id: '02', name: 'Verificar y agregar', status: 'upcoming' },
    ])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        handleChange('members', members);
    }, [members])

    const searchMember = async () => {
        await api.familyMembers.getMulti(null, null, null, null, { gov_id: member.govId }).then((response) => {
            if (response.length > 0) {
                setMember(response[0]);
            }
        }).then(() => setLoadingMember(false)).catch((error) => (console.log(error), setLoadingMember(false)))
    }

    const clearMember = () => {
        handleMember("firstName", "")
        handleMember("lastName", "")
        handleMember("govId", "")
        handleMember("email", "")
        handleMember("birthDate", today)
        handleMember("isHead", false)
        handleMember("cellphone", "")
        handleMember("inheritancePercentage", "")
        handleMember("relation", "")
        handleMember("idx", null)
        handleMember("id", null)
        setIndex(null)
        setLoadingMember(true)
        handleError("firstName", "")
        handleError("lastName", "")
        handleError("govId", "")
        handleError("email", "")
        handleError("birthDate", "")
    }

    const editMember = (index) => {
        setIndex(index)
        setMember({ ...client.members[index] });
        setOpen(true);
    }

    const goNext = () => {
        if (member.govId !== "") {
            searchMember();
            setSteps([
                { id: '01', name: 'Ingresar número de cédula', status: 'complete' },
                { id: '02', name: 'Verificar y agregar', status: 'current' }])
        } else {
            handleError('govId', 'Este campo es obligatorio')
        }
    }

    const addMember = () => {
        let error = false;
        if (!member.firstName) {
            handleError('firstName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('firstName', '')
        }
        if (!member.lastName) {
            handleError('lastName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('lastName', '')
        }
        if (!member.email) {
            handleError('email', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('email', '')
        }
        if (!member.birthDate) {
            handleError('birthDate', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('birthDate', '')
        }
        if (!error) {
            if (index || index === 0) {
                const obj = members.slice();
                obj[index] = member;
                setMembers(obj);
                setOpen(false);
                setSteps([
                    { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                    { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]);
                clearMember();
            } else if (member.id) {
                setMembers((prev) => ([...prev, { id: member.id, govId: member.govId, relation: member.relation, firstName: member.firstName, lastName: member.lastName, email: member.email, birthDate: member.birthDate, inheritancePercentage: member.inheritancePercentage, isHead: member.isHead }]))
                setOpen(false);
                setSteps([
                    { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                    { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]);
                clearMember();
            }
            else {
                setMembers((prev) => ([...prev, { govId: member.govId, relation: member.relation, firstName: member.firstName, lastName: member.lastName, email: member.email, birthDate: member.birthDate, inheritancePercentage: member.inheritancePercentage, isHead: member.isHead }]))
                setOpen(false);
                setSteps([
                    { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                    { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]);
                clearMember();
            }
        }
    }

    const content = (
        <>
            <Steps steps={steps} />
            {steps[0].status === "current" ?
                <>
                    <TextInput handleChange={handleMember} value={member.govId} name="govId" containerClassName="mt-4 w-1/3 text-left" label="Ingresa el número de cédula" placeholder="C.I." />
                    {errors.govId !== "" ?
                        <div className="text-left mt-2 text-sm text-red-600">
                            {errors.govId}
                        </div>
                        : null}
                    <button onClick={() => goNext()}
                        type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm">
                        Siguiente
                    </button>
                </>
                : null}
            {steps[1].status === "current" ?
                <>
                    {loadingMember ?
                        <div className="flex h-60 items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <>
                            <p className="mt-4 font-semibold text-left text-blue-600">Crear nuevo integrante</p>
                            <p className="font-semibold text-left text-gray-600">C.I. {member.govId}</p>
                            <div className="mt-4 grid grid-cols-2 gap-x-4 w-3/4" >
                                <TextInput errorMessage={errors.firstName} name="firstName" handleChange={handleMember} value={member.firstName} containerClassName="text-left" label="Nombres" placeholder="Nombres" />
                                <TextInput errorMessage={errors.lastName} name="lastName" handleChange={handleMember} value={member.lastName} containerClassName="text-left" label="Apellidos" placeholder="Apellidos" />
                                <TextInput errorMessage={errors.email} name="email" handleChange={handleMember} value={member.email} containerClassName="text-left" label="Correo electrónico" />
                                <DateInput errorMessage={errors.birthDate} name="birthDate" handleChange={handleMember} defaultValue={member.birthDate} containerClassName="text-left" label="Fecha de nacimiento" />
                                <TextInput name="relation" handleChange={handleMember} value={member.relation} containerClassName="text-left" label="Vínculo familiar" placeholder="Vínculo" />
                                <TextInput name="cellphone" handleChange={handleMember} value={member.cellphone} containerClassName="text-left" label="Número de teléfono" />
                                <InlineCheckbox handleChange={handleMember} name="isHead" value={member.isHead} label="Es cabeza de familia" />
                            </div>
                            <button onClick={() => addMember()} type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm">
                                Agregar
                            </button>
                        </>}
                </>
                : null}
        </>
    )

    const handleSubmit = async () => {
        let error = false
        if (!client.name) {
            handleError('name', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('name', '')
        }
        if (!client.clientPassword) {
            handleError('clientPassword', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('clientPassword', '')
        }
        if (client.members.length === 0) {
            handleError('members', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('members', '')
        }
        if (!error) {
            await api.clients.create(client).then((response1) => {
                setClient(response1);
                return response1;
            }).then((response1) => {
                members.map((element, elemIdx) => {
                    if (!element.id) {
                        api.familyMembers.create(element).then((response2) => {
                            const obj = members.slice();
                            obj[elemIdx] = response2;
                            setMembers(obj);
                            api.memberDetails.create({ familyMember: response2.id, client: response1.id, relation: element.relation, isHead: element.isHead ? true : false }).then((response) => console.log(response))
                        })
                    } else {
                        api.memberDetails.create({ familyMember: element.id, client: response1.id, relation: element.relation, isHead: element.isHead ? true : false }).then((response) => console.log(response))
                    }
                })
            })
                .then((error) => error ? null : navigate('/home'))
                .catch(response => {
                    for (var element in response.data) {
                        error = true
                        if (Object.prototype.hasOwnProperty.call(response.data, element)) {
                            if (response.data[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response.data[element])
                        }
                    }
                })
        }
    };


    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal size="sm:max-w-2xl" open={open} onClose={() => (setOpen(false), clearMember(), setSteps([
                { id: '01', name: 'Ingresar número de cédula', status: 'current' },
                { id: '02', name: 'Verificar y agregar', status: 'upcoming' }]))} content={content} title="Nuevo integrante" />
            <Header current="clients" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Clientes</h1>
                        </div>
                    </div>
                    <div className="mt-10 ml-10 sm:ml-0 sm:px-20 pb-6">
                        <div className="bg-white overflow-hidden shadow rounded-lg w-[90%]">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="flex justify-between">
                                    <p className="font-semibold text-2xl ml-4">Completa el formulario</p>
                                    <button onClick={() => handleSubmit()} className="inline-flex items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900">
                                        Guardar
                                    </button>
                                </div>
                                {errors.call !== "" ?
                                    <>
                                        <p className="mt-2 text-sm text-red-600">
                                            {errors.call}
                                        </p>
                                    </>
                                    : null}
                                <div className="mt-6 ml-4">
                                    <p className="font-semibold text-blue-600">Datos generales</p>
                                    <p className="font-medium text-sm text-gray-600">Nombre de la familia</p>
                                    <TextInput errorMessage={errors.name} handleChange={handleChange} name="name" value={client.name} containerClassName="w-fit sm:w-1/5" placeholder="Nombre" />
                                    <p className="mt-4 font-medium text-sm text-gray-600">Nombre en código (Opcional)</p>
                                    <TextInput errorMessage={errors.namecode} handleChange={handleChange} name="namecode" value={client.namecode} containerClassName="w-fit sm:w-1/5" placeholder="Nombre en código" />
                                    <p className="mt-4 font-medium text-sm text-gray-600">Contraseña del cliente</p>
                                    <div className="mt-1 w-fit sm:w-1/5 relative rounded-md flex flex-wrap">
                                        <input
                                            type="password"
                                            name="clientPassword"
                                            id="clientPassword"
                                            onChange={(event) => handleChange("clientPassword", event.target.value)}
                                            required
                                            className="shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md"
                                            placeholder="Contraseña"
                                        />
                                        {errors.clientPassword !== "" ?
                                            <>
                                                <p className="mt-2 text-sm text-red-600">
                                                    {errors.clientPassword}
                                                </p>
                                            </>
                                            : null}
                                    </div>
                                    <div className="mt-6">
                                        <p className="font-semibold text-blue-600">Integrantes</p>
                                        <div className="mt-4 flex flex-col space-y-4">
                                            {members.length !== 0 ?
                                                members.map((member, idx) => {
                                                    return (
                                                        <div className="flex flex-col">
                                                            <p className="font-medium text-sm text-gray-600">{member.relation}</p>
                                                            <div className="flex justify-between w-1/3">
                                                                <p>{member.firstName + ' ' + member.lastName}</p>
                                                                <button onClick={() => editMember(idx)} className="font-semibold text-blue-600">
                                                                    Editar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                        <button onClick={() => setOpen(true)} className="inline-flex mt-4 mr-20 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200">
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Agregar integrante
                                        </button>
                                        {client.members.length === 0 ?
                                            <>
                                                <p className="mt-2 text-sm text-red-600">
                                                    {errors.members}
                                                </p>
                                            </>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </div >
    );
}
