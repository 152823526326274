import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import api from "../../services/api";
import Breadcrumbs from "../../components/Breadcrumbs";
import Modal from "../../components/Modal";

const columns = [
    { name: 'name', displayText: 'Nombre común' },
    { name: 'abbreviation', displayText: 'Abreviación' }
]

export default function InvestmentFundsPage() {

    const [loading, setLoading] = useState(true)
    const [funds, setFunds] = useState([]);
    const [open, setOpen] = useState(false);
    const [idFund, setIdFund] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [fund, setFund] = useState({
        name: '',
        abbreviation: ''
    })
    const [errors, setErrors] = useState({
        name: '',
        abbreviation: ''
    });

    const handleChange = (field, value) => setFund((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const clearFields = () => {
        handleChange("name", "");
        handleChange("abbreviation", "");
        setIdFund(null);
    }

    const fetchFund = async (id) => {
        await api.funds.getOne(id).then((response) => {
            setFund({
                name: response.name,
                abbreviation: response.abbreviation
            })
            setLoading(false)
        })
    }

    const fetchFunds = async () => {
        await api.funds.getMulti().then((response) => {
            setFunds(response.map(({ name, abbreviation, id, }) => {
                return ({
                    name: name, abbreviation: abbreviation, id: id,
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const deleteFund = async (id) => {
        await api.funds.delete(id).catch(response => { console.log(response) }).then(() => (fetchFunds(), setOpenConfirmation(false), clearFields()))
    }

    const openConfirmationModal = (id) => {
        setIdFund(id)
        setOpenConfirmation(true)
    }

    const handleSubmit = () => {
        let error = false
        if (idFund) {
            api.funds.update(idFund, fund).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchFunds(), setOpen(false), clearFields()))
        } else {
            if (!fund.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!fund.abbreviation) {
                handleError('abbreviation', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('abbreviation', '')
            }
            api.funds.create(fund).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchFunds(), setOpen(false), clearFields()))
        }
    };

    const pages = [
        { name: 'Configuraciones', href: '/configurations', current: false },
        { name: 'Acciones', href: '#', current: true }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchFunds();
    }, [])

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={fund.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre"
                    />
                </div>
                <div className="relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="abbreviation"
                        id="abbreviation"
                        value={fund.abbreviation}
                        onChange={(event) => handleChange('abbreviation', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Abreviación (tres letras)"
                    />
                </div>
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Agregar
            </button>
        </>
    )

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este fondo?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteFund(idFund)}
            >
                Aceptar
            </button>
        </div>
    )

    const editFund = (id) => {
        setIdFund(id)
        fetchFund(id)
        setOpen(true)
    }

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Nuevo fondo" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar fondo" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="configurations" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Fondos</h1>
                        </div>
                        <button
                            onClick={() => setOpen(true)}
                            type="button"
                            className="inline-flex sm:mr-20 mr-2 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                        >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Crear nuevo fondo
                        </button>
                    </div>
                    <div className="mt-10 sm:px-20 px-2 sm:w-3/4">
                        <Table editRow={editFund} deleteRow={openConfirmationModal} columns={columns} rows={funds} />
                    </div>
                </>
            }
        </div >
    );
}
