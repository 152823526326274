import { ChartPieIcon, CurrencyDollarIcon, DatabaseIcon, LocationMarkerIcon, OfficeBuildingIcon, ViewGridIcon } from "@heroicons/react/outline";
import { TrendingUpIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import GridList from "../../components/GridList";
import Header from "../../components/Header";

const actions = [
    {
        title: 'Países',
        href: '/configurations/countries',
        icon: LocationMarkerIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Acciones',
        href: '/configurations/stocks',
        icon: TrendingUpIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Fondos',
        href: '/configurations/investment-funds',
        icon: TrendingUpIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Activos',
        href: '/configurations/assets',
        icon: ViewGridIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50'
    },
    {
        title: 'Monedas',
        href: '/configurations/currencies',
        icon: CurrencyDollarIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Entidades',
        href: '/configurations/entities',
        icon: OfficeBuildingIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Sectores',
        href: '/configurations/sectors',
        icon: ChartPieIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
    {
        title: 'Categorías de activos',
        href: '/configurations/asset-categories',
        icon: ChartPieIcon,
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
    },
]

export default function ConfigurationsPage() {

    const [loading, setLoading] = useState(true)
    const [input, setInput] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Header current="configurations" />
            <div className="bg-white py-6 border-b-2">
                <h1 className="sm:ml-28 ml-4 font-bold text-3xl drop-shadow">Configuraciones</h1>
            </div>
            <div className="my-10 sm:mx-20 mx-4 bg-gray-50">
                <GridList actions={actions} />
            </div>
        </div >
    );
}
