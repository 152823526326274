import React from "react";
import { Link } from "react-router-dom";

export default function Table(props) {

    return (
        <div className={`flex flex-col`}>
            <div className={`-my-2 ${!props.nonScrolleable && 'overflow-x-auto'} sm:-mx-6 lg:-mx-8`}>
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {props.columns.map((column) => (
                                        <>
                                            <th
                                                key={column.name}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                {column.displayText}
                                            </th>
                                        </>
                                    ))}
                                    {props.actionText && <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.rows.map((row, rowIdx) => (
                                    <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        {props.columns.map((column, colIdx) => (
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                                {row[column.name] ? row[column.name] : '--'}
                                            </td>
                                        ))}
                                        {props.actionText && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <Link to={`/${props.module}/${row.id}`} key={rowIdx} className="font-semibold text-blue-700 hover:text-blue-800">
                                                {props.actionText}
                                            </Link>
                                        </td>}
                                        {props.editRow && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button onClick={() => props.editRow(row.id)} key={rowIdx} className="font-semibold text-blue-700 hover:text-blue-800">
                                                Editar
                                            </button>
                                        </td>}
                                        {props.deleteRow && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button onClick={() => props.deleteRow(row.id)} key={rowIdx} className="font-semibold text-gray-500 hover:text-gray-700">
                                                Eliminar
                                            </button>
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )

}