import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/authContext'
import PDF from '../assets/Disclaimer.pdf';

export default function ClientLogin() {

    const [username, setUsername] = useState("")
    const [clientID, setClientID] = useState("")
    const [password, setPassword] = useState("")
    const [clientPassword, setClientPassword] = useState("")
    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const auth = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault()
        setErrorMessage('')
        setLoading(true)
        auth.clientSignIn(clientID, clientPassword, username, password)
            .then((response) => {
                setLoading(false)
                navigate(`/clients/${clientID}`);
            })
            .catch((e) => {
                setLoading(false)
                setErrorMessage(e.detail)
                return Promise.reject(e.detail)
            });
    }

    return (
        <div className="min-h-screen select-none h-full flex flex-col justify-center py-12 sm:px-6 bg-gray-50 lg:px-8">
            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="h-auto w-56 mx-auto"
                    src="/logo.png"
                    alt="TFO"
                />
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <h1 className="text-center text-3xl text-gray-900 font-bold">Oficina Financiera de Familias</h1>
                <p className="text-center text-sm leading-5 font-medium text-gray-600">O <button type="button" onClick={() => navigate('/login')} className="text-center text-sm leading-5 font-medium text-blue-600">ingresar con credenciales de administrador</button></p>
                <p className="text-sm font-normal mt-4 text-center text-gray-500">La presente sección del sitio es de uso exclusivo de clientes existentes previamente registrados siguiendo las normas internacionales de Conocer al Cliente KYC.</p>
                <div className="py-8 px-4 border border-transparent sm:px-10">
                    <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
                        <div className="isolate -space-y-px rounded-md shadow-sm">
                            <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="text"
                                    name="clientID"
                                    id="clientID"
                                    onChange={(event) => setClientID(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="ID Cliente"
                                />
                            </div>
                            <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="password"
                                    name="clientPassword"
                                    id="clientPassword"
                                    onChange={(event) => setClientPassword(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Contraseña Cliente"
                                />
                            </div>
                            <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={(event) => setUsername(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Usuario TFO"
                                />
                            </div>
                            <div className="flex relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Contraseña TFO"
                                />
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <div className="text-sm">
                                <a href='/tyc' target="_blank" rel="noreferrer" className="font-medium mx-auto text-blue-600 hover:text-blue-800">
                                    Términos y Condiciones
                                </a>
                            </div>
                        </div>

                        {error_message === "" ?
                            null
                            : <div className="bg-red-100 mt-12 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">Credenciales invalidas.</span>
                            </div>
                        }

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800"
                            >
                                {loading ?
                                    <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Ingresar'
                                }
                            </button>
                        </div>

                        <p className="text-sm font-normal text-center text-gray-500">
                            El logotipo de escudo azul y cruz blanca y la denominación “Trust Family Office” son marcas registradas de Trust Family Office S.A.
                        </p>
                    </form>
                </div>
            </div >

        </div >
    );
}
