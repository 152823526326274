import { Disclosure, Menu, Transition } from '@headlessui/react'
import { useState } from 'react'
import { XIcon, ViewListIcon, BellIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BurgerMenu(props) {

    return (
        <Disclosure as="nav" className="border-gray-200 bg-white sm:hidden">
            {({ open }) => (
                <>
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex ml-2 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <>
                                            <ViewListIcon className="block h-6 w-6" aria-hidden="true" />
                                        </>
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="mt-10 sm:hidden">
                        <div className="space-y-1">
                            <Disclosure.Button className="inline-flex items-center ml-6 justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <ViewListIcon className="block h-6 w-6" aria-hidden="true" />
                            </Disclosure.Button>
                        </div>
                        <div className="border border-gray-200 rounded-md pt-2 pb-2">
                            <div className="space-y-1">
                                <Disclosure.Button
                                    key='clients'
                                    as="a"
                                    href='/clients'
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                >
                                    Clientes
                                </Disclosure.Button>
                                <Disclosure.Button
                                    key='users'
                                    as="a"
                                    href='/users'
                                    className="block border-t border-gray-300 px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                >
                                    Usuarios
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>


    )
}