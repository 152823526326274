export default function RightInlineCheckbox(props) {
    return (
        <div>
            <legend className="sr-only">{props.name}</legend>
            <div className="divide-y divide-gray-200">
                <div className="relative flex items-start py-4">
                    <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="comments" className={props.labelClassName ? props.labelClassName : "font-medium text-gray-700"}>
                            {props.label}
                        </label>
                        <p id="comments-description" className="text-gray-500">
                            {props.description}
                        </p>
                    </div>
                    <div className="ml-3 flex items-center h-5">
                        <input
                            id={props.id}
                            onChange={(event) => props.idx != null ? props.handleChange(props.name, event.target.checked, props.idx) : props.handleChange(props.name, event.target.checked)}
                            aria-describedby={`${props.name}-description`}
                            name={props.name}
                            checked={props.value}
                            disabled={props.disabled}
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}