import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import Table from "../../components/Table";
import api from "../../services/api";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import Modal from "../../components/Modal";

const columns = [
    { name: 'name', displayText: 'País' },
    { name: 'shortName', displayText: 'Abreviación' },
]

export default function CountriesPage() {

    const [loading, setLoading] = useState(true)
    const [countries, setCountries] = useState([]);
    const [idCountry, setIdCountry] = useState(null);
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [country, setCountry] = useState({
        name: "",
        shortName: "",
        showIndividually: false,
    })
    const [errors, setErrors] = useState({
        name: '',
        shortName: '',
    });

    const handleChange = (field, value) => setCountry((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const clearFields = () => {
        handleChange("name", "");
        handleChange("shortName", "");
        handleChange("showIndividually", false);
        setIdCountry(null);
    }

    const fetchCountry = async (idCountry) => {
        await api.countries.getOne(idCountry).then((response) => {
            setCountry({
                name: response.name,
                shortName: response.shortName,
                showIndividually: response.showIndividually,
            })
            setLoading(false)
        })
    }

    const fetchCountries = async () => {
        await api.countries.getMulti().then((response) => {
            setCountries(response.map(({ name, shortName, showIndividually, id, }) => {
                return ({
                    name: name, id: id, shortName: shortName, showIndividually: showIndividually
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const deleteCountry = async (id) => {
        await api.countries.delete(id).catch(response => { console.log(response) }).then(() => (fetchCountries(), setOpenConfirmation(false), clearFields()))
    }

    const editCountry = (id) => {
        setIdCountry(id)
        fetchCountry(id)
        setOpen(true)
    }

    const openConfirmationModal = (id) => {
        setIdCountry(id)
        setOpenConfirmation(true)
    }

    const handleSubmit = () => {
        let error = false
        if (idCountry) {
            api.countries.update(idCountry, country).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchCountries(), setOpen(false), clearFields()))
        } else {
            if (!country.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!country.shortName) {
                handleError('shortName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('shortName', '')
            }
            api.countries.create(country).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchCountries(), setOpen(false), clearFields()))
        }
    };

    const pages = [
        { name: 'Configuraciones', href: '/configurations', current: false },
        { name: 'Países', href: '#', current: true }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCountries();
    }, [])

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={country.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre"
                    />
                </div>
                <div className="flex relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="shortName"
                        id="shortName"
                        value={country.shortName}
                        onChange={(event) => handleChange('shortName', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre corto (tres letras)"
                    />
                </div>
                <InlineCheckbox name="showIndividually" value={country.showIndividually} handleChange={handleChange} label="Mostrar individualmente" />
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Agregar
            </button>
        </>
    )

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este país?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteCountry(idCountry)}
            >
                Aceptar
            </button>
        </div>
    )

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Nuevo país" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar país" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="configurations" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Países</h1>
                        </div>
                        <button
                            onClick={() => {
                                setIdCountry(null);
                                setOpen(true);
                            }}
                            type="button"
                            className="inline-flex sm:mr-20 mr-2 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                        >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Crear nuevo país
                        </button>
                    </div>
                    <div className="mt-10 px-2 sm:px-20 sm:w-3/4">
                        <Table editRow={editCountry} deleteRow={openConfirmationModal} columns={columns} rows={countries} />
                    </div>
                </>}
        </div >
    );
}
