import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../context/authContext'
import api from "../services/api";

export default function ResetPasswordPage() {

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const { clientId } = useParams()
    const { userId } = useParams()
    const { token } = useParams()

    const navigate = useNavigate();
    const auth = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault()
        let error = false
        if (password !== password2) {
            setErrorMessage('Las contraseñas deben ser iguales.')
            error = true
        }
        if (!error) {
            await api.reset_clientpassword.changeClientPassword(password, clientId, userId, token)
                .then((response) => {
                    setMessage(response)
                })
                .catch(response => {
                    console.log(response)
                    for (var element in response.data) {
                        error = true
                        if (Object.prototype.hasOwnProperty.call(response.data, element)) {
                            if (response.data[element] != 'Este campo no puede estar en blanco.')
                                setErrorMessage(response.data[element])
                        }
                    }
                }
                )
        }
    }

    return (
        <div className="min-h-screen select-none h-full flex flex-col justify-center py-12 sm:px-6 bg-gray-50 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="h-auto w-56 mx-auto"
                    src="/logo.png"
                    alt="TFO"
                />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {message.status === "success" ?
                        <div className="bg-green-100 mt-4 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">Su contraseña ha sido modificada</span>
                        </div>
                        :
                        <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Nueva contraseña
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={(event) => setPassword(event.target.value)}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                                    Verificar contraseña
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password2"
                                        name="password2"
                                        type="password"
                                        onChange={(event) => setPassword2(event.target.value)}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                {errorMessage === "" ?
                                    null
                                    : <div className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                        <span className="block sm:inline">{errorMessage}</span>
                                    </div>
                                }
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800"
                                >
                                    Aceptar
                                </button>
                            </div>
                        </form>
                    }
                </div>
            </div >
        </div >
    );
}