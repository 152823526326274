import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../services/api";
import Modal from "../../components/Modal";
import { BriefcaseIcon, CashIcon, CurrencyDollarIcon, DocumentTextIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import { TrendingUpIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/Tabs";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";

const columns = [
    { name: 'asset', displayText: 'Nombre' },
    { name: 'familyMembers', displayText: 'Titular' },
    { name: 'amount', displayText: 'Monto' },
    { name: 'currency', displayText: 'Moneda' }
]

const tabs = [
    {
        id: 1,
        name: 'Efectivo y productos financieros',
        href: '/configurations/countries',
        icon: CashIcon,
    },
    {
        id: 2,
        name: 'Pagarés y bonos de renta fija',
        href: '/configurations/stocks',
        icon: DocumentTextIcon,
    },
    {
        id: 3,
        name: 'Participación en empresas',
        href: '/configurations/assets',
        icon: BriefcaseIcon,
    },
    {
        id: 4,
        name: 'Productos estructurados',
        href: '/configurations/currencies',
        icon: TrendingUpIcon,
    },
    {
        id: 5,
        name: 'Bienes raíces',
        href: '/configurations/entities',
        icon: OfficeBuildingIcon,
    },
    {
        id: 6,
        name: 'Activos alternativos directos',
        href: '/configurations/sectors',
        icon: CurrencyDollarIcon,
    },
]

export default function ClientAssets() {

    const [loading, setLoading] = useState(true)
    const [currencies, setCurrencies] = useState([]);
    const [assets, setAssets] = useState([]);
    const [clientAssets, setClientAssets] = useState([]);
    const { idClient } = useParams();
    const [idAsset, setIdAsset] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [search, setSearch] = useState("")
    const [client, setClient] = useState(null);
    const [tab, setTab] = useState(1);
    const navigate = useNavigate();
    const pages = [
        { name: 'Panel de control', href: `/clients/${idClient}`, current: false },
        { name: 'Ver activos', href: '#', current: true }
    ]

    const handleSearch = (name, value) => {
        setSearch(value);
    }

    const numberSeparatedwithPoints = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const fetchPreAssetFields = async () => {
        await api.assets.getMulti(null, null, null, null, { category: tab }).then((response) => {
            setAssets(response);
        })
    }

    const fetchConfig = async () => {
        await api.currencies.getMulti().then((response) => {
            setCurrencies(response);
        })

        await api.clients.getOne(idClient).then((response) => {
            setClient(response);
        })
    }

    const openConfirmationModal = (id) => {
        setIdAsset(id)
        setOpenConfirmation(true)
    }

    const deleteAsset = async (id) => {
        await api.clientAssets.delete(id).catch(response => { console.log(response) }).then(() => (fetchClientAssets(), setOpenConfirmation(false), setIdAsset(null)))
    }

    const fetchClientAssets = async () => {
        await api.clientAssets.getMulti(null, null, null, null, { client: idClient, asset__category: tab }).then((response) => {
            setClientAssets(response.map(({ id, asset, amount, currency, familyMembers }) => {
                let array = []
                familyMembers.map((member) => array.push([client.members.find((element) => element.familyMemberId === member).firstName + ' ' + client.members.find((element) => element.familyMemberId === member).lastName, <br />]))
                return ({
                    id: id, asset: assets.find((element) => element.id === asset).name, familyMembers: array, amount: numberSeparatedwithPoints(amount), currency: currencies.find((element) => element.id === currency).name
                })
            }))
        })
        setLoading(false);
    }

    const searchAsset = async () => {
        setLoading(true)
        await api.clientAssets.getMulti(null, null, null, null, { client: idClient, asset__category: tab, asset__name: search }).then((response) => {
            setClientAssets(response.map(({ id, asset, amount, currency, familyMembers }) => {
                let array = []
                familyMembers.map((member) => array.push([client.members.find((element) => element.familyMemberId === member).firstName + ' ' + client.members.find((element) => element.familyMemberId === member).lastName, <br />]))
                return ({
                    id: id, asset: assets.find((element) => element.id === asset).name, familyMembers: array, amount: numberSeparatedwithPoints(amount), currency: currencies.find((element) => element.id === currency).name
                })
            }))
            setLoading(false)
        }).catch((error) => {
            console.log('Error al traer/filtrar pacientes: ', error.response?.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (clientAssets) {
            setClientAssets(clientAssets.sort((a, b) => {
                if (a.familyMembers[0] < b.familyMembers[0])
                    return -1;
                else if (a.familyMembers[0] > b.familyMembers[0])
                    return 1;
                else
                    if (a.asset < b.asset)
                        return -1;
                    else if (a.asset > b.asset)
                        return 1;
                    else
                        return 0;
            }))
        }
    }, [clientAssets])

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este activo?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteAsset(idAsset)}
            >
                Aceptar
            </button>
        </div>
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchConfig();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true);
        setSearch(null);
        fetchPreAssetFields();
    }, [tab])

    useEffect(() => {
        if (assets.length > 0 && currencies.length > 0 && client) {
            fetchClientAssets()
        }
    }, [assets, currencies, client])

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Eliminar activo" content={confirmationContent} onClose={() => (setIdAsset(null), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="clients" />
            <div className="flex py-6 bg-white justify-between">
                <div className="flex flex-col px-2 sm:px-24 space-y-2 lg:flex-shrink-0">
                    <Breadcrumbs pages={pages} />
                    <h1 className="font-bold text-3xl ml-4">Activos cargados</h1>
                    <Tabs tabs={tabs} tab={tab} setTab={setTab} />
                </div>
            </div>
            {loading ?
                <div className="flex h-full mt-20 items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <div className="flex flex-col mt-2 px-2 sm:px-24 pb-6 space-y-4">
                    <TextInput containerClassName="w-fit sm:w-1/4" placeholder="Buscar" value={search} name="search" handleChange={handleSearch} onKeyPress={(event) => event.key === 'Enter' ? searchAsset() : null} />
                    <Table deleteRow={openConfirmationModal} columns={columns} rows={clientAssets} actionText="Editar" module={`clients/${idClient}/assets`} />
                </div>
            }
        </div >
    );
}
