import axios from "axios";

const errorCodes = {
  badRequest: "badRequest",
  unauthorized: "unauthorized",
  forbidden: "forbidden",
  notFound: "notFound",
  serverError: "serverError",
  unexpected: "unexpected",
  invalidCredentials: "invalidCredentials",
};

class API {
  constructor(collection) {
    this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
  }

  _handleError(error) {
    if (error.response?.status === 400)
      return Promise.reject({
        code: errorCodes.badRequest,
        data: error.response?.data,
      });
    if (error.response?.status === 401)
      return Promise.reject({
        code: errorCodes.unauthorized,
        data: error.response?.data,
      });
    if (error.response?.status === 403)
      return Promise.reject({
        code: errorCodes.forbidden,
        data: error.response?.data,
      });
    if (error.response?.status === 404)
      return Promise.reject({
        code: errorCodes.notFound,
        data: error.response?.data,
      });
    if (500 <= error.response?.status <= 599)
      return Promise.reject({
        code: errorCodes.serverError,
        data: error.response?.data,
      });
    return Promise.reject({
      code: errorCodes.unexpected,
      data: error.response?.data,
    });
  }

  async getMulti(
    ordering = null,
    search = null,
    limit = 100,
    offset = 0,
    extra = {}
  ) {
    const params = { limit, offset, ...extra };
    if (ordering) params.ordering = ordering;
    if (search && search.length > 0) params.search = search;
    try {
      const response = await axios.get(`${this.collectionUrl}/`, { params });
      return Promise.resolve(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getOne(id) {
    try {
      const response = await axios.get(`${this.collectionUrl}/${id}/`);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async create(data) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async update(id, data, partial = false) {
    try {
      let response;
      if (partial)
        response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
      else response = await axios.put(`${this.collectionUrl}/${id}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async delete(id) {
    try {
      return await axios.delete(`${this.collectionUrl}/${id}/`);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class UserAPI extends API {
  async getMe() {
    try {
      const response = await axios.get(`${this.collectionUrl}/me/`);
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(errorCodes.invalidCredentials);
    }
  }

  async getToken(username, password) {
    try {
      const response = await axios.post(`${this.collectionUrl}/token/`, {
        username,
        password,
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(errorCodes.invalidCredentials);
    }
  }

  async changePassword(newPassword) {
    try {
      const response = await axios.post(
        `${this.collectionUrl}/me/change-password/`,
        { new: newPassword }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class PasswordAPI extends API {
  async sendEmail(email) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, { email: email });
      return Promise.resolve(response.data)
    } catch (e) {
      return this._handleError(e)
    }
  }

  async changeClientPassword(password, clientId, userId, token) {
    try {
      const response = await axios.post(`${this.collectionUrl}/${clientId}/${userId}/${token}/`, { password: password })
      return Promise.resolve(response.data)
    } catch (e) {
      return this._handleError(e)
    }
  }

  async changePassword(password, uuid, token) {
    try {
      const response = await axios.post(`${this.collectionUrl}/${uuid}/${token}/`, { password: password })
      return Promise.resolve(response.data)
    } catch (e) {
      return this._handleError(e)
    }
  }
}

class ClientLoginAPI extends API {
  async getToken(clientId, clientPassword, username, password) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, {
        clientId,
        clientPassword,
        username,
        password
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(errorCodes.invalidCredentials);
    }
  }
}

class DownloadAPI extends API {

  async exportReports(clientId) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, { client: clientId }, { responseType: 'blob' })
      return response
    } catch (e) {
      return this._handleError(e)
    }
  }

}


const api = {
  users: new UserAPI("users"),
  resetClientPassword: new API('reset-client-password'),
  clientLogin: new ClientLoginAPI("client-login"),
  assetCategories: new API("asset-categories"),
  assets: new API("assets"),
  countries: new API("countries"),
  currencies: new API("currencies"),
  entities: new API("entities"),
  entityCategories: new API("entity-categories"),
  investmentSectors: new API("investment-sectors"),
  stocks: new API("stocks"),
  clients: new API("clients"),
  funds: new API("investment-funds"),
  familyMembers: new API("family-members"),
  memberDetails: new API("member-details"),
  clientAssets: new API("client-assets"),
  generalReport: new API("total-heritage-report"),
  assetTypeReport: new API("asset-type-report"),
  geographicalAreaReport: new API("geographical-area-report"),
  investmentSectorReport: new API("investment-sector-report"),
  nationalRiskReport: new API("national-risk-report"),
  internationalRiskReport: new API("international-risk-report"),
  consolidatedRiskReport: new API("consolidated-risk-report"),
  tfoRiskReport: new API("tfo-risk-report"),
  entitiesReport: new API("entity-report"),
  memberReport: new API("member-report"),
  deadlineReport: new API("deadline-report"),
  nominalRateReport: new API("nominal-rate-report"),
  officialRiskReturnReport: new API("official-risk-return-report"),
  tfoRiskReturnReport: new API("tfo-risk-return-report"),
  reset_password: new PasswordAPI('reset-password'),
  reset_clientpassword: new PasswordAPI('reset-client-password'),
  download: new DownloadAPI('export-report')
};

export default api;
export { errorCodes, API };
