import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import api from "../../services/api";
import Breadcrumbs from "../../components/Breadcrumbs";
import Modal from "../../components/Modal";
import InlineCheckbox from "../../components/InlineCheckbox";

const columns = [
    { name: 'name', displayText: 'Nombre' },
    { name: 'category', displayText: 'Categoría' }
]

export default function AssetsPage() {

    const [loading, setLoading] = useState(true)
    const [assets, setAssets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idAsset, setIdAsset] = useState(null);
    const [asset, setAsset] = useState({
        name: "",
        category: "1",
        property: false,
        notes: false,
        currency: false,
        amount: false,
        entity: false,
        stock: false,
        country: false,
        investmentSector: false,
        nominalRate: false,
        capitalizationPeriod: false,
        ytmPerformance: false,
        rent: false,
        purchasePrice: false,
        actualPrice: false,
        profitOrLoss: false,
        ratingRiskPy: false,
        ratingRiskIntl: false,
        tfoRisk: false,
        originalDeadline: false,
        investmentDate: false,
        expirationDate: false,
        todaysDate: false,
        daysToExpiration: false,
    })
    const [errors, setErrors] = useState({
        name: '',
        category: ''
    });

    const handleChange = (field, value) => setAsset((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const clearFields = () => {
        handleChange("name", "");
        handleChange("category", "");
        setIdAsset(null);
    }

    const fetchCategories = async () => {
        await api.assetCategories.getMulti().then((response) => {
            setCategories(response.map(({ name, id }) => {
                return (<option value={id} label={name}>{name}</option>)
            }))
            setLoading(false)
        })
    }

    const fetchAsset = async (id) => {
        await api.assets.getOne(id).then((response) => {
            setAsset({
                name: response.name,
                category: response.category,
                property: response.property,
                notes: response.notes,
                currency: response.currency,
                stock: response.stock,
                amount: response.amount,
                entity: response.entity,
                country: response.country,
                investmentSector: response.investmentSector,
                investmentFund: response.investmentFund,
                nominalRate: response.nominalRate,
                capitalizationPeriod: response.capitalizationPeriod,
                rent: response.rent,
                purchasePrice: response.purchasePrice,
                actualPrice: response.actualPrice,
                profitOrLoss: response.profitOrLoss,
                ratingRiskPy: response.ratingRiskPy,
                ratingRiskIntl: response.ratingRiskIntl,
                tfoRisk: response.tfoRisk,
                originalDeadline: response.originalDeadline,
                investmentDate: response.investmentDate,
                expirationDate: response.expirationDate,
                todaysDate: response.todaysDate,
                daysToExpiration: response.daysToExpiration,
            })
            setLoading(false)
        })
    }

    const fetchAssets = async () => {
        await api.assetCategories.getMulti().then((categories) => {
            api.assets.getMulti().then((response) => {
                setAssets(response.map(({ name, category, id, }) => {
                    return ({
                        name: name, id: id, category: categories.find((element) => element.id === category).name
                    })
                }));
                setLoading(false);
            })
        });
    }

    const handleSubmit = () => {
        let error = false
        if (idAsset) {
            api.assets.update(idAsset, asset).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchAssets(), setOpen(false), clearFields()))
        } else {
            if (!asset.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            api.assets.create(asset).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchAssets(), setOpen(false), clearFields()))
        }
    };

    const pages = [
        { name: 'Configuraciones', href: '/configurations', current: false },
        { name: 'Activos', href: '#', current: true }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCategories();
        fetchAssets();
    }, [])

    const editAsset = (id) => {
        setIdAsset(id)
        fetchAsset(id)
        setOpen(true)
    }

    const deleteAsset = async (id) => {
        await api.assets.delete(id).catch(response => { console.log(response) }).then(() => (fetchAssets(), setOpenConfirmation(false), clearFields()))
    }

    const openConfirmationModal = (id) => {
        setIdAsset(id)
        setOpenConfirmation(true)
    }

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md sm:w-1/4">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={asset.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre"
                    />
                </div>
                <div className="relative border border-gray-300 rounded-md rounded-t-none focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <select
                        type="text"
                        name="category"
                        id="category"
                        value={asset.category}
                        onChange={(event) => handleChange('category', event.target.value)}
                        className="shadow-sm bg-white w-full h-9 pl-2 border border-gray-300 block sm:text-sm rounded-md rounded-t-none"
                        placeholder="Categoría"
                    >
                        {categories}
                    </select>
                </div>
            </div>
            <InlineCheckbox value={asset.property} name="property" handleChange={handleChange} label="Inmueble" />
            <InlineCheckbox value={asset.notes} name="notes" handleChange={handleChange} label="Observaciones" />
            <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-x-8" >
                <div className="mt-4 colspan-1 flex flex-col">
                    <p className="text-base text-gray-900">Campos principales</p>
                    <InlineCheckbox value={asset.currency} name="currency" handleChange={handleChange} label="Moneda" />
                    <InlineCheckbox value={asset.amount} name="amount" handleChange={handleChange} label="Monto" />
                    <InlineCheckbox value={asset.stock} name="stock" handleChange={handleChange} label="Acción" />
                    <InlineCheckbox value={asset.investmentFund} name="investmentFund" handleChange={handleChange} label="Fondo" />
                    <InlineCheckbox value={asset.entity} name="entity" handleChange={handleChange} label="Institución" />
                    <InlineCheckbox value={asset.country} name="country" handleChange={handleChange} label="País de la Inversión" />
                    <InlineCheckbox value={asset.investmentSector} name="investmentSector" handleChange={handleChange} label="Sector de la Inversión" />
                </div>
                <div className="mt-4 colspan-1 flex flex-col">
                    <p>Campos de rentabilidad</p>
                    <InlineCheckbox value={asset.nominalRate} name="nominalRate" handleChange={handleChange} label="Tasa Nominal" />
                    <InlineCheckbox value={asset.capitalizationPeriod} name="capitalizationPeriod" handleChange={handleChange} label="Periodo Capitaliz Interés" />
                    <InlineCheckbox value={asset.rent} name="rent" handleChange={handleChange} label="Renta Anual/Mensual" />
                    <InlineCheckbox value={asset.purchasePrice} name="purchasePrice" handleChange={handleChange} label="Precio Compra" />
                    <InlineCheckbox value={asset.actualPrice} name="actualPrice" handleChange={handleChange} label="Precio Actual" />
                    <InlineCheckbox value={asset.profitOrLoss} name="profitOrLoss" handleChange={handleChange} label="Ganancia/Perdida" />
                </div>
                <div className="mt-4 colspan-1 flex flex-col">
                    <p>Campos de riesgo de la inversión</p>
                    <InlineCheckbox value={asset.ratingRiskPy} name="ratingRiskPy" handleChange={handleChange} label="Riesgo Calificadora PY" />
                    <InlineCheckbox value={asset.ratingRiskIntl} name="ratingRiskIntl" handleChange={handleChange} label="Riesgo Calificadora Intl" />
                    <InlineCheckbox value={asset.tfoRisk} name="tfoRisk" handleChange={handleChange} label="Riesgo TFO" />
                </div>
                <div className="mt-4 colspan-1 flex flex-col">
                    <p>Campos de horizonte de inversión</p>
                    <InlineCheckbox value={asset.originalDeadline} name="originalDeadline" handleChange={handleChange} label="Plazo Original" />
                    <InlineCheckbox value={asset.investmentDate} name="investmentDate" handleChange={handleChange} label="Fecha de Inversión" />
                    <InlineCheckbox value={asset.expirationDate} name="expirationDate" handleChange={handleChange} label="Fecha de Vencimiento" />
                    <InlineCheckbox value={asset.todaysDate} name="todaysDate" handleChange={handleChange} label="Fecha de Hoy" />
                    <InlineCheckbox value={asset.daysToExpiration} name="daysToExpiration" handleChange={handleChange} label="Días hasta el Vencimiento" />
                </div>
            </div>
            <button type="button" className="mt-8 w-1/4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Aceptar
            </button>
        </>
    )

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este activo?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteAsset(idAsset)}
            >
                Aceptar
            </button>
        </div>
    )

    useEffect(() => {
        api.assetCategories.getMulti().then((categories) => {
            api.assets.getMulti().then((response) => {
                setAssets(response.map(({ name, category, id, }) => {
                    return ({
                        name: name, id: id, category: categories.find((element) => element.id === category).name
                    })
                }));
                setLoading(false);
            })
        });
    }, []);

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Activo" size="sm:max-w-7xl" classNames="text-start" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar activo" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="configurations" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>
                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Activos</h1>
                        </div>
                        <button
                            onClick={() => setOpen(true)}
                            type="button"
                            className="inline-flex mr-2 sm:mr-20 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                        >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Crear nuevo activo
                        </button>
                    </div>
                    <div className="mt-10 sm:px-20 px-2 sm:w-3/4">
                        <Table editRow={editAsset} deleteRow={openConfirmationModal} columns={columns} rows={assets} />
                    </div>
                </>
            }
        </div >
    );
}
