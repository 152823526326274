export default function Textarea(props) {
    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.label} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1">
                <textarea
                    rows={props.rows}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder}
                    onChange={(event) => props.idx != null ? props.handleChange(props.name, event.target.value, props.idx) : props.handleChange(props.name, event.target.value)}
                    className="shadow-sm focus:ring-blue-500 px-2 py-1 focus:border-blue-500 block w-full sm:text-sm border border-gray-700 rounded"
                    defaultValue={props.defaultValue ? props.defaultValue : ''}
                />
            </div>
        </div>
    )
}