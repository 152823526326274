import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../services/api";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";

const columns = [
    { name: 'id', displayText: 'ID' },
    { name: 'name', displayText: 'Nombre' },
]

const pages = [
    { name: `Inicio`, href: `/home`, current: false },
    { name: 'Clientes', href: '#', current: true }
]

export default function ClientPage() {

    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [clients, setClients] = useState([]);
    const [idClient, setIdClient] = useState(null);
    const [client, setClient] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({
        namecode: '',
        name: '',
    });
    const navigate = useNavigate();

    const handleChange = (field, value) => setClient((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const handleSearch = (name, value) => {
        setSearch(value);
    }

    const clearFields = () => {
        handleChange("name", "");
        handleChange("namecode", "");
        setIdClient(null);
    }

    const deleteClient = async (id) => {
        await api.clients.delete(id).catch(response => { console.log(response) }).then(() => (fetchClients(), setOpenConfirmation(false), clearFields()))
    }

    const fetchClients = async () => {
        setLoading(true)
        await api.clients.getMulti().then((response) => {
            setClients(response.map(({ name, namecode, id }) => {
                return ({
                    name: namecode ? namecode : name, id: id
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const fetchClient = async (id) => {
        await api.clients.getOne(id).then((response) => {
            setClient(response)
            setLoading(false)
        })
    }

    const editClient = (id) => {
        setIdClient(id)
        fetchClient(id)
        setOpen(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        api.clients.getMulti().then((response) => {
            setClients(response.map(({ name, namecode, id }) => {
                return ({
                    name: namecode ? namecode : name, id: id
                })
            })
            );
            setLoading(false);
        }
        ).catch((error) => (handleError('get', error), setLoading(false)));
    }, [])

    const searchClient = async () => {
        setLoading(true)
        await api.clients.getMulti(null, null, null, null, { search: search }).then((response) => {
            setClients(response.map(({ name, namecode, id }) => {
                return ({
                    name: namecode ? namecode : name, id: id
                })
            })
            );
            setLoading(false)
        }).catch((error) => {
            console.log('Error al traer/filtrar clientes: ', error.response?.data)
            setLoading(false)
        })
    }

    const handleSubmit = () => {
        let error = false
        if (!client.name) {
            handleError('name', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('name', '')
        }
        if (!error) {
            api.clients.update(idClient, client).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchClients(), setOpen(false), clearFields()))
        }
    };


    const ErrorMessage = (props) => {
        if (props.errors?.get?.code === "unauthorized") {
            return (
                <p className="text-red-500 font-semibold ml-8">Error en la autenticación. Vuelva a iniciar sesión y pruebe de nuevo.</p>
            )
        }
    }

    const openConfirmationModal = (id) => {
        setIdClient(id)
        setOpenConfirmation(true)
    }

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este cliente?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteClient(idClient)}
            >
                Aceptar
            </button>
        </div>
    )

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={client.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre"
                    />
                </div>
                <div className="relative border border-gray-300 rounded-md rounded-t-none focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="namecode"
                        id="namecode"
                        value={client.namecode}
                        onChange={(event) => handleChange('namecode', event.target.value)}
                        className="shadow-sm bg-white w-full h-9 pl-2 border border-gray-300 block sm:text-sm"
                        placeholder="Código de nombre"
                    />
                </div>
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Agregar
            </button>
        </>
    )

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Editar cliente" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar cliente" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="clients" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">Clientes</h1>
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-6 mr-10">
                            <TextInput containerClassName="ml-6 sm:ml-0 w-full" placeholder="Buscar" value={search} name="search" handleChange={handleSearch} onKeyPress={(event) => event.key === 'Enter' ? searchClient() : null} />

                            <button
                                onClick={() => {
                                    navigate('/clients/new');
                                }}
                                type="button"
                                className="inline-flex w-full justify-center items-center h-fit sm:h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                            >
                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Crear nuevo cliente
                            </button>
                        </div>
                    </div>
                    <div className="mt-10 px-2 sm:px-20 ">
                        {errors?.get ?
                            <ErrorMessage error={errors?.get} />
                            :
                            <>
                                <Table module="clients" actionText="Ver" editRow={editClient} deleteRow={openConfirmationModal} columns={columns} rows={clients} />
                            </>
                        }
                    </div>
                </>}
        </div >
    );
}
