import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Modal from '../components/Modal';
import { Dialog } from "@headlessui/react";
import { useAuth } from '../context/authContext'
import api from "../services/api";

export default function LoginPage() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [openForgotPassword, setOpenForgotPassword] = useState(false)
    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const auth = useAuth();

    const recoverPassword = async () => {
        await api.reset_password.sendEmail(email)
            .then((response) => {
                console.log(response)
                setOpenForgotPassword(false)
            })
            .catch((error) => console.log(error))
    }

    const forgotPassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Recuperar Contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Correo Electrónico
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                            <input
                                type="email"
                                name="email"
                                onChange={(event) => setEmail(event.target.value)}
                                id="email"
                                className="block mt-1 w-full h-8 pl-2 pr-10 sm:text-sm border border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        recoverPassword()
                    }
                >
                    Aceptar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenForgotPassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const handleSubmit = async (event) => {
        event.preventDefault()
        setErrorMessage('')
        setLoading(true)
        auth.signIn(username, password)
            .then((response) => {
                setLoading(false)
                navigate('/home');
            })
            .catch((e) => {
                setLoading(false)
                setErrorMessage(e.detail)
                return Promise.reject(e.detail)
            });
    }

    return (
        <div className="min-h-screen h-full select-none flex flex-col justify-center py-12 sm:px-6 bg-gray-50 lg:px-8">
            <Modal content={forgotPassword} open={openForgotPassword} onClose={() => setOpenForgotPassword(false)} setOpen={setOpenForgotPassword} />
            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="h-auto w-56 mx-auto"
                    src="/logo.png"
                    alt="TFO"
                />
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <h1 className="text-center text-3xl text-gray-900 font-bold">Oficina Financiera de Familias</h1>
                <p className="text-center text-sm leading-5 font-medium text-gray-600">O <button type="button" onClick={() => navigate('/client-login')} className="text-center text-sm leading-5 font-medium text-blue-600">ingresar con credenciales de cliente</button></p>
                <div className="py-8 px-4 border border-transparent sm:px-10">
                    <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
                        <div className="isolate -space-y-px rounded-md shadow-sm">
                            <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={(event) => setUsername(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Usuario"
                                />
                            </div>
                            <div className="flex relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Contraseña"
                                />
                            </div>
                        </div>

                        {error_message === "" ?
                            null
                            : <div className="bg-red-100 mt-12 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">Credenciales invalidas.</span>
                            </div>
                        }

                        <div className="flex justify-center">
                            <div className="text-sm">
                                <button type="button" onClick={() => setOpenForgotPassword(true)} className="font-medium mx-auto text-blue-600 hover:text-blue-800">
                                    ¿Olvidaste tu contraseña?
                                </button>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800"
                            >
                                {loading ?
                                    <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Ingresar'
                                }
                            </button>
                        </div>

                        <p className="text-sm font-normal text-center text-gray-500">
                            El logotipo de escudo azul y cruz blanca y la denominación “Trust Family Office” son marcas registradas de Trust Family Office S.A.
                        </p>
                    </form>
                </div>
            </div >
        </div >
    );
}
