import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetClientPasswordPage from "./pages/ResetClientPasswordPage";
import ClientPage from './pages/clients/ClientPage';
import UsersPage from "./pages/users/UsersPage";
import UserNewPage from './pages/users/UserNewPage';
import ConfigurationsPage from './pages/configurations/ConfigurationsPage';
import StocksPage from './pages/configurations/StocksPage';
import CountriesPage from './pages/configurations/CountriesPage';
import AssetsPage from './pages/configurations/AssetsPage';
import CurrenciesPage from './pages/configurations/CurrenciesPage';
import EntitiesPage from './pages/configurations/EntitiesPage';
import SectorsPage from './pages/configurations/SectorsPage';
import ClientLoginPage from './pages/ClientLogin';
import ClientDashboard from './pages/clients/ClientDashboard';
import NewClientPage from './pages/clients/NewClientPage';
import ClientAssets from './pages/clients/ClientAssets';
import NewClientAssetPage from './pages/clients/NewClientAssetPage';
import SelectionPage from './pages/clients/SelectionPage';
import ReportsPage from './pages/clients/ReportsPage';
import AssetCategoriesPage from './pages/configurations/AssetCategoriesPage';
import InvestmentFundsPage from './pages/configurations/InvestmentFundsPage';
import TosPage from './pages/TosPage';

function App() {

  const auth = useAuth();
  const isClient = localStorage.getItem('ClientAccessToken') ? true : false

  const authenticatedRoutes = (
    <Routes>
      <Route path="/home" element={<SelectionPage />} />
      <Route exact path="/clients" element={<ClientPage />} />
      <Route exact path="/clients/new" element={<NewClientPage />} />
      <Route path="/clients/:idClient" element={<ClientDashboard />} />
      <Route path="/clients/:idClient/assets" element={<ClientAssets />} />
      <Route path="/clients/:idClient/assets/new/:idCategoryAsset" element={<NewClientAssetPage />} />
      <Route path="/clients/:idClient/assets/:idClientAsset" element={<NewClientAssetPage />} />
      <Route path="/clients/:idClient/reports" element={<ReportsPage />} />
      <Route exact path="/users" element={<UsersPage />} />
      <Route exact path="/users/new" element={<UserNewPage />} />
      <Route path="/users/:idUser" element={<UserNewPage />} />
      <Route exact path="/configurations" element={<ConfigurationsPage />} />
      <Route exact path="/configurations/countries" element={<CountriesPage />} />
      <Route exact path="/configurations/stocks" element={<StocksPage />} />
      <Route exact path="/configurations/investment-funds" element={<InvestmentFundsPage />} />
      <Route exact path="/configurations/assets" element={<AssetsPage />} />
      <Route exact path="/configurations/currencies" element={<CurrenciesPage />} />
      <Route exact path="/configurations/entities" element={<EntitiesPage />} />
      <Route exact path="/configurations/sectors" element={<SectorsPage />} />
      <Route exact path="/configurations/asset-categories" element={<AssetCategoriesPage />} />
      <Route path="/reset-client-password/:clientId/:userId/:token" element={<ResetClientPasswordPage />} />
      <Route path="/reset-password/:uuid/:token" element={<ResetPasswordPage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );

  const clientAuthenticatedRoutes = (
    <Routes>
      <Route path="/clients/:idClient" element={<ClientDashboard />} />
      <Route path="/clients/:idClient/assets" element={<ClientAssets />} />
      <Route path="/clients/:idClient/assets/new/:idCategoryAsset" element={<NewClientAssetPage />} />
      <Route path="/clients/:idClient/assets/:idClientAsset" element={<NewClientAssetPage />} />
      <Route path="/clients/:idClient/reports" element={<ReportsPage />} />
      <Route path="/reset-client-password/:clientId/:userId/:token" element={<ResetClientPasswordPage />} />
      <Route path="/reset-password/:uuid/:token" element={<ResetPasswordPage />} />
    </Routes>
  );

  return (
    <Router>
      {auth.isAuthenticated ? (
        isClient ?
          clientAuthenticatedRoutes :
          authenticatedRoutes
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/client-login" element={<ClientLoginPage />} />
          <Route path="/tyc" element={<TosPage />} />
          <Route path="/reset-password/:uuid/:token" element={<ResetPasswordPage />} />
          <Route path="/reset-client-password/:clientId/:userId/:token" element={<ResetClientPasswordPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );

}

export default App;
