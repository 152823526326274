import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/solid";
import Breadcrumbs from "../../components/Breadcrumbs";
import api from "../../services/api";
import Modal from "../../components/Modal";
import Textarea from "../../components/Textarea";

const columns = [
    { name: 'name', displayText: 'Nombre' },
    { name: 'abbreviation', displayText: 'Abreviación' }
]

export default function AssetCategoriesPage() {

    const [loading, setLoading] = useState(true)
    const [assetCategories, setAssetCategories] = useState([]);
    const [idCategory, setIdCategory] = useState(null);
    const [category, setCategory] = useState(null);
    const [open, setOpen] = useState(false);

    const pages = [
        { name: 'Configuraciones', href: '/configurations', current: false },
        { name: 'Tipos de activos', href: '#', current: true }
    ]

    const editCategory = (id) => {
        setIdCategory(id)
        fetchCategory(id)
        setOpen(true)
    }

    const clearFields = () => {
        setCategory(null);
        setIdCategory(null);
    }

    const handleChange = (field, value) => setCategory((prev) => ({ ...prev, [field]: value }));

    useEffect(() => {
        window.scrollTo(0, 0)
        api.assetCategories.getMulti().then((response) => {
            setAssetCategories(response.map(({ name, id, abbreviation, description }) => {
                return ({
                    name: name, id: id, abbreviation: abbreviation, description: description
                })
            })
            );
            setLoading(false);
        }
        )
    }, [])

    const fetchCategory = async (idCategory) => {
        await api.assetCategories.getOne(idCategory).then((response) => {
            setCategory({
                name: response.name,
                description: response.description
            })
            setLoading(false)
        })
    }

    const fetchCategories = async () => {
        await api.assetCategories.getMulti().then((response) => {
            setAssetCategories(response.map(({ name, id, abbreviation, description }) => {
                return ({
                    name: name, id: id, abbreviation: abbreviation, description: description
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const handleSubmit = () => {
        api.assetCategories.update(idCategory, category).then(() => (fetchCategories(), setOpen(false), clearFields()))
    };

    const content = (
        <>
            <div className="isolate rounded-md text-left space-y-4">
                <p className="font-semibold text-gray-700 text-xl">{category?.name}</p>
                <Textarea rows={4} defaultValue={category?.description} handleChange={handleChange} name="description" />
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Aceptar
            </button>
        </>
    )


    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Categoría de activo" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Header current="configurations" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>
                    <div className="flex flex-col sm:px-24 mt-10 justify-between">
                        <Breadcrumbs pages={pages} />
                        <h1 className="ml-4 font-bold text-3xl">Tipos de Activos</h1>
                    </div>
                    <div className="mt-10 px-2 sm:px-20 sm:w-3/4">
                        <Table editRow={editCategory} module="configurations/asset-categories" columns={columns} rows={assetCategories} />
                    </div>
                </>}
        </div >
    );
}
