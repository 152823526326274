import React, { useEffect } from "react";
import { useState } from 'react'
import {
    ChartBarIcon,
    EyeIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import api from "../../services/api";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const roles = [
    <option key={1} value={1} label="Staff">Staff</option>,
    <option key={2} value={2} label="Administrador">Administrador</option>
]

export default function UserNewPage() {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const { idUser } = useParams();
    const [user, setUser] = useState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        profile: {
            govId: "",
            role: 1
        }
    })
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        govId: '',
    });

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleGovId = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, govId: value } }))
    const handleRole = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, role: value } }))
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const fetchUser = async () => {
        await api.users.getOne(idUser).then((response) => {
            setUser({
                firstName: response.firstName,
                lastName: response.lastName,
                username: response.username,
                profile: response.profile,
                email: response.email,
                password: response.password,
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (idUser) {
            fetchUser();
        } else {
            setLoading(false);
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        if (idUser) {
            api.users.update(idUser, user)
            navigate('/users')
        } else {
            if (!user.firstName) {
                handleError('firstName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('firstName', '')
            }
            if (!user.lastName) {
                handleError('lastName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('lastName', '')
            }
            if (!user.username) {
                handleError('username', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('username', '')
            }
            if (!user.email) {
                handleError('email', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('email', '')
            }
            if (!user.password) {
                handleError('password', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('password', '')
            }
            if (!user.profile.govId) {
                handleError('govId', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('govId', '')
            }
            api.users.create(user).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : navigate('/users'))
        }
    };

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Header current="users" />
            <div className="flex flex-col flex-1 ml-6 mt-6">
                {loading ?
                    <div className="flex h-screen items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <div className="flex justify-between">
                            <h1 className="ml-28 font-bold text-3xl">{idUser ? 'Editar Usuario' : 'Nuevo Usuario'}</h1>
                            <button
                                type="submit"
                                className="h-10 mr-8 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-gray-700 hover:bg-gray-800"
                            >
                                Guardar
                            </button>
                        </div>
                        <div className="flex-col flex-1 ml-24 mt-6 space-y-12 divide-y divide-gray-200">
                            <div className="max-w-7xl flex-col items-center px-4">
                                <div className="flex flex-wrap gap-x-8">
                                    <TextInput errorMessage={errors.firstName} value={user.firstName} handleChange={handleChange} name="firstName" label="Nombres" />
                                    <TextInput errorMessage={errors.lastName} value={user.lastName} handleChange={handleChange} name="lastName" label="Apellidos" />
                                </div>
                                <div className="flex flex-wrap gap-x-8">
                                    <TextInput errorMessage={errors.govId} value={user.profile?.govId} handleChange={handleGovId} name="govId" label="C.I." />
                                    <SelectInput value={user.profile?.role} className={"shadow-sm bg-white w-full h-9 pl-2 border border-gray-300 block sm:text-sm rounded-md"} handleChange={handleRole} name="role" label="Rol" options={roles} />
                                </div>
                            </div>
                            <div className="max-w-7xl items-center px-4">
                                <div className="mt-6 flex flex-wrap gap-x-8">
                                    <TextInput errorMessage={errors.username} handleChange={handleChange} value={user.username} name="username" label="Nombre de usuario" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                    <TextInput errorMessage={errors.email} handleChange={handleChange} value={user.email} name="email" label="Correo electrónico" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}
