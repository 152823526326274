import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumbs';
import { BarChart, Bar, PieChart, Pie, Legend, Cell, Tooltip, ReferenceLine, Scatter, ResponsiveContainer, XAxis, CartesianGrid, YAxis, Label, ScatterChart, LabelList } from 'recharts';
import RightInlineCheckbox from "../../components/RightInlineCheckbox";
import Dropdown from "../../components/Dropdown";


const COLORS = ["#3498DB", "#E67E22", "#27AE60", "#E74C3C", "#9B59B6", "#904040", "#E54BB6", "#1ABC9C", "#BCBD22", "#17BECF", "#2980B9", "#D35400", "#16A085", "#C0392B", "#8E44AD", "#8E0101", "#EC11AA", "#2ECC71", "#F1F10F", "#08ABBB", "#5DADE2", "#F0B27A", "#52BE80", "#EC7063", "#A569BD", "#AD5B5B", "#D431A2", "#4FD1B7", "#D7D84E", "#0ED4E8", "#7FB3D5", "#DC7633", "#7DCEA0", "#D98880", "#C39BD3", "#991616", "#FA38C0", "#2CF581", "#D9D930", "#3ACBDA"]
const BLUE = ["#1E3A8A", "#1D4ED8", "#3B82F6", "#60A5FA", "#93C5FD"]
const RED = ["#7F1D1D", "#991B1B", "#B91C1C", "#DC2626", "#EF4444", "#F87171", "#FCA5A5"]
const ORANGE = ["#9A3412", "#C2410C", "#EA580C", "#FCA5A5", "#F97316", "#FB923C", "#FDBA74"]
const AMBER = ["#78350F", "#92400E", "#B45309", "#D97706", "#F59E0B", "#FBBF24", "#FCD34D"]
const EMERALD = ["#064E3B", "#065F46", "#047857", "#059669", "#10B981", "#34D399", "#6EE7B7"]
const CYAN = ["#164E63", "#155E75", "#0E7490", "#0891B2", "#06B6D4", "#22D3EE", "#67E8F9"]
const INDIGO = ["#312E81", "#3730A3", "#4338CA", "#4F46E5", "#6366F1", "#818CF8", "#A5B4FC"]
const PURPLE = ["#D8B4FE", "#C084FC", "#A855F7", "#9333EA", "#7E22CE", "#6B21A8", "#581C87"]
const PINK = ["#F9A8D4", "#F472B6", "#EC4899", "#DB2777", "#BE185D", "#9D174D", "#831843"]
const ROSE = ["#FDA4AF", "#FB7185", "#F43F5E", "#E11D48", "#BE123C", "#9F1239", "#881337"]

const items = [
    { name: 'Dólares', value: 0 },
    { name: 'Guaraníes', value: 1 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ReportsPage() {

    const [loading, setLoading] = useState(true)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [client, setClient] = useState([]);
    const [currency, setCurrency] = useState(0);
    const [data, setData] = useState([]);
    const { idClient } = useParams();
    const navigate = useNavigate();
    const [isStaff, setIsStaff] = useState(localStorage.getItem('TFOaccessToken') ? true : false)
    const pages = isStaff ? [
        { name: 'Clientes', href: '/clients', current: false },
        { name: 'Panel de control', href: `/clients/${idClient}/`, current: false },
        { name: 'Reportes', href: '#', current: true }
    ] : [
        { name: 'Panel de control', href: `/clients/${idClient}/`, current: false },
        { name: 'Reportes', href: '#', current: true }
    ]
    const [totalHeritage, setTotalHeritage] = useState(false);
    const [assetType, setAssetType] = useState(false);
    const [geographicalArea, setGeographicalArea] = useState(false);
    const [investmentSector, setInvestmentSector] = useState(false);
    const [nationalRisk, setNationalRisk] = useState(false);
    const [internationalRisk, setInternationalRisk] = useState(false);
    const [consolidatedRisk, setConsolidatedRisk] = useState(false);
    const [tfoRisk, setTfoRisk] = useState(false);
    const [riskReturn, setRiskReturn] = useState(false);
    const [clientRiskReturn, setClientRiskReturn] = useState(false);
    const [tfoRiskReturn, setTfoRiskReturn] = useState(false);
    const [tfoClientRiskReturn, setTfoClientRiskReturn] = useState(false);
    const [entities, setEntities] = useState(false);
    const [member, setMember] = useState(false);
    const [deadline, setDeadline] = useState(false);
    const [nominalRate, setNominalRate] = useState(false);
    const [intlNominalRate, setIntlNominalRate] = useState(false);
    const [totalHeritageData, setTotalHeritageData] = useState([]);
    const [assetTypeData, setAssetTypeData] = useState([]);
    const [count, setCount] = useState(0);
    const [geographicalAreaData, setGeographicalAreaData] = useState([]);
    const [investmentSectorData, setInvestmentSectorData] = useState([]);
    const [nationalRiskData, setNationalRiskData] = useState([]);
    const [internationalRiskData, setInternationalRiskData] = useState([]);
    const [consolidatedRiskData, setConsolidatedRiskData] = useState([]);
    const [tfoRiskData, setTfoRiskData] = useState([]);
    const [entitiesData, setEntitiesData] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [deadlineData, setDeadlineData] = useState([]);
    const [pygNominalRateData, setPygNominalRateData] = useState([]);
    const [intlNominalRateData, setIntlNominalRateData] = useState([]);
    const [riskReturnData, setRiskReturnData] = useState([]);
    const [clientRiskReturnData, setClientRiskReturnData] = useState([]);
    const [average, setAverage] = useState([]);
    const [tfoRiskReturnData, setTfoRiskReturnData] = useState([]);
    const [tfoClientRiskReturnData, setTfoClientRiskReturnData] = useState([]);
    const [tfoAverage, setTfoAverage] = useState([]);
    const [assetTypeURL, setAssetTypeURL] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        api.clients.getOne(idClient).then((response) => {
            setClient(response);
        }
        )

        api.generalReport.getOne(idClient).then((response) => {
            if (response.properties.percentage > 0) {
                setData([{ name: 'Inmuebles', value: response.properties.percentage, usdAmount: response.properties.usdConversion, pygAmount: response.properties.pygConversion },
                ...response.currencies.map((currency) => {
                    return (
                        { name: currency.name, value: currency.percentage, pygAmount: currency.pygConversion, usdAmount: currency.usdConversion }
                    )
                })
                ]);
            } else {
                setData([
                    ...response.currencies.map((currency) => {
                        return (
                            { name: currency.name, value: currency.percentage, pygAmount: currency.pygConversion, usdAmount: currency.usdConversion }
                        )
                    })
                ]);
            }

        }).catch((error) => console.log(error))

        api.assetTypeReport.getOne(idClient).then((response) => {
            setAssetTypeData(response.categories);
        }).catch((error) => console.log(error))

        api.geographicalAreaReport.getOne(idClient).then((response) => {
            setGeographicalAreaData(response.countries);
        }).catch((error) => console.log(error))

        api.investmentSectorReport.getOne(idClient).then((response) => {
            setInvestmentSectorData(response.investmentSectors);
        }).catch((error) => console.log(error))

        api.nationalRiskReport.getOne(idClient).then((response) => {
            response.nationalRisk.map((element) => {
                if (element.name === "---------") {
                    setNationalRiskData((prev) => [...prev, { name: 'Indefinido', percentage: element.percentage, usdAmount: element.usdAmount, pygAmount: element.pygAmount }])
                } else {
                    setNationalRiskData((prev) => [...prev, element]);
                }
            })
        }).catch((error) => console.log(error))

        api.internationalRiskReport.getOne(idClient).then((response) => {
            response.internationalRisk.map((element) => {
                if (element.name === "---------") {
                    setInternationalRiskData((prev) => [...prev, { name: 'Indefinido', percentage: element.percentage, usdAmount: element.usdAmount, pygAmount: element.pygAmount }])
                } else {
                    setInternationalRiskData((prev) => [...prev, element]);
                }
            })
        }).catch((error) => console.log(error))

        api.consolidatedRiskReport.getOne(idClient).then((response) => {
            response.consolidatedRisk.map((element) => {
                setConsolidatedRiskData((prev) => [...prev, { name: element.value, percentage: element.percentage, usdAmount: element.usdAmount, pygAmount: element.pygAmount }])
            })
        }).catch((error) => console.log(error))

        api.tfoRiskReport.getOne(idClient).then((response) => {
            response.tfoRisk.map((element) => {
                if (element.name === null) {
                    setTfoRiskData((prev) => [...prev, { name: 'Indefinido', percentage: element.percentage, usdAmount: element.usdAmount, pygAmount: element.pygAmount }])
                } else {
                    setTfoRiskData((prev) => [...prev, element]);
                }
            })
        }).catch((error) => console.log(error))

        api.entitiesReport.getOne(idClient).then((response) => {
            setEntitiesData(response.entities);
        }).catch((error) => console.log(error))

        api.memberReport.getOne(idClient).then((response) => {
            setMemberData(response.familyMembers)
        }).catch((error) => console.log(error))

        api.deadlineReport.getOne(idClient).then((response) => {
            response.deadline.map((element, idx) => {
                setDeadlineData((prev) => [...prev, { ...element, idx: idx }])
            })
        }).catch((error) => console.log(error))

        api.nominalRateReport.getOne(idClient).then((response) => {
            setPygNominalRateData(response.pygNominalRate)
            setIntlNominalRateData(response.intlNominalRate)
        }).catch((error) => console.log(error))

        api.officialRiskReturnReport.getOne(idClient).then((response) => {
            for (const [key, value] of Object.entries(response.clientStatus)) {
                setClientRiskReturnData((prev) => [...prev, { name: key, qualification: value.qualification, pygAmount: value.returnOverRiskGs, usdAmount: value.returnOverRiskUsd, rateGs: value.rateGs, rateUsd: value.rateUsd }])
            }
            for (const [key, value] of Object.entries(response.marketStatus)) {
                setRiskReturnData((prev) => [...prev, { name: key, qualification: value.qualification, pygAmount: value.returnOverRiskGs, usdAmount: value.returnOverRiskUsd, rateGs: value.rateGs, rateUsd: value.rateUsd }])
            }
            setAverage(response.averages);
        })

        api.tfoRiskReturnReport.getOne(idClient).then((response) => {
            for (const [key, value] of Object.entries(response.clientStatus)) {
                setTfoClientRiskReturnData((prev) => [...prev, { name: key, qualification: value.qualification, pygAmount: value.returnOverRiskGs, usdAmount: value.returnOverRiskUsd, rateGs: value.rateGs, rateUsd: value.rateUsd }])
            }
            for (const [key, value] of Object.entries(response.marketStatus)) {
                setTfoRiskReturnData((prev) => [...prev, { name: key, qualification: value.qualification, pygAmount: value.returnOverRiskGs, usdAmount: value.returnOverRiskUsd, rateGs: value.rateGs, rateUsd: value.rateUsd }])
            }
            setTfoAverage(response.averages);
        })

        setLoading(false);
    }, [])

    const downloadPDF = () => {
        setDownloadLoading(true);
        api.download.exportReports(idClient).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Reportes.pdf`);
            link.click();
            setDownloadLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setDownloadLoading(false)
        })
    }

    const countChecks = (...states) => {
        return states.reduce((count, state) => count + +state, 0);
    }

    useEffect(() => {
        setCount(countChecks(totalHeritage, geographicalArea, assetType, investmentSector,
            nationalRisk, internationalRisk, tfoRisk, consolidatedRisk, entities, member, deadline, nominalRate, intlNominalRate, clientRiskReturn, riskReturn, tfoClientRiskReturn, tfoRiskReturn))
    }, [totalHeritage, geographicalArea, assetType, investmentSector,
        nationalRisk, internationalRisk, tfoRisk, consolidatedRisk, entities, member, deadline, nominalRate, intlNominalRate, clientRiskReturn, riskReturn, tfoClientRiskReturn, tfoRiskReturn])

    let renderLabel = function (entry) {
        return (entry.name + ' ' + entry.value + ' %')
    }

    let renderCategoryLabel = function (entry) {
        return entry.abbreviation + ' ' + entry.value + ' %';
    }

    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <ul>
                {
                    payload.map((entry, index) => {
                        if (entry.payload.value < 1) {
                            <li key={`item-${index}`}>{entry.value}: {entry.payload.value} %</li>
                        } else {
                            return null
                        }
                    })
                }
            </ul>
        );
    }

    const numberSeparatedwithPoints = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="px-4 py-2 z-40 bg-white border border-gray-300">
                    <p className="label">{`${payload[0].name} : ${numberSeparatedwithPoints(payload[0].payload.amount.toString().includes('.') ? payload[0].payload.amount.toString().substring(0, payload[0].payload.amount.toString().indexOf('.')) : payload[0].payload.amount)} ${currency === 1 ? '₲' : '$'}`}</p>
                </div>
            );
        }

        return null;
    };

    const DeadlineCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let str = ""
            payload[0].payload.assets.forEach((element) => str += (element.entity + " \n" + element.name + " \n"));
            return (
                <div className="px-4 py-2 z-20 bg-white border border-gray-300">
                    <p className="label" style={{ whiteSpace: 'break-spaces' }}>{`${str}${payload[0].payload.days} días (${Math.round(payload[0].payload.days / 365 * 100) / 100} años)`}</p>
                </div>
            );
        }

        return null;
    };

    const NominalRateCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="px-4 py-2 z-20 bg-white border border-gray-300">
                    <p className="label" style={{ whiteSpace: 'break-spaces' }}>{`Tasa ${payload[0].payload.value}${payload[0].payload.value === 'Variable' ? '' : `% ${payload[0].payload.currencies ? '(' + payload[0].payload.currencies[0] + ')' : ''}`} \n${currency === 1 ? numberSeparatedwithPoints(payload[0].payload.pygAmount.toString().includes('.') ? payload[0].payload.pygAmount.toString().substring(0, payload[0].payload.pygAmount.toString().indexOf('.')) : payload[0].payload.pygAmount) + ' ₲' : payload[0].payload.usdAmount + ' $'} `}</p>
                </div>
            );
        }

        return null;
    };

    const RiskReturnCustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="px-4 py-2 z-20 bg-white border border-gray-300">
                    <p className="label" style={{ whiteSpace: 'break-spaces' }}>{`${payload[0].payload.name} \n${currency === 1 ? numberSeparatedwithPoints(payload[0].payload.pygAmount.toString().includes('.') ? payload[0].payload.pygAmount.toString().substring(0, payload[0].payload.pygAmount.toString().indexOf('.')) : payload[0].payload.pygAmount) : numberSeparatedwithPoints(payload[0].payload.usdAmount.toString().includes('.') ? payload[0].payload.usdAmount.toString().substring(0, payload[0].payload.usdAmount.toString().indexOf('.')) : payload[0].payload.usdAmount)} `}</p>
                </div>
            );
        }
        return null;
    };

    const GeneralReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data.map((element) => { return ({ name: element.name, value: element.value, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    minAngle={1}
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const AssetTypeReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={assetTypeData.map((element) => { return ({ name: element.name, value: element.percentage, abbreviation: element.abbreviation, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    minAngle={1}
                    label={window.innerWidth < 640 ? null : renderCategoryLabel}
                >
                    {assetTypeData.map((entry, index) => (
                        < Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                </Pie>
                {count > 2 ? null : <Legend wrapperStyle={window.innerWidth < 640 ? { fontSize: "12px", padding: '12px', whiteSpace: "break-spaces" } : { fontSize: "12px", whiteSpace: "break-spaces" }} formatter={window.innerWidth < 640 ? (value, entry, index) => `${entry.payload.abbreviation}: ${entry.payload.value} %` : (value, entry, index) => `${value.substring(0, value.search(' '))} \n ${value.substring(value.search(' '))} (${entry.payload.abbreviation}): ${entry.payload.value} %`} align={window.innerWidth < 640 ? "center" : "left"} verticalAlign={window.innerWidth < 640 ? "bottom" : "middle"} layout={window.innerWidth < 640 ? "horizontal" : "vertical"} />}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const GeographicalAreaReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={geographicalAreaData.map((element) => { return ({ fullName: element.name, name: element.shortName, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={10}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {geographicalAreaData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {(count > 2 && window.innerWidth < 640) ? null : <Legend wrapperStyle={window.innerWidth < 640 ? { fontSize: "12px", padding: '12px', whiteSpace: "break-spaces" } : { fontSize: "12px", whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.fullName} (${entry.payload.name}): ${entry.payload.value} %`} align="left" verticalAlign="middle" layout="vertical" />}
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const InvestmentSectorReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 500 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={investmentSectorData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={10}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {investmentSectorData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const NationalRiskReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? nationalRiskData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : nationalRiskData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={5}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {nationalRiskData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const InternationalRiskReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? internationalRiskData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : internationalRiskData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={5}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {internationalRiskData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const ConsolidatedRiskReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? consolidatedRiskData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : consolidatedRiskData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={10}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {consolidatedRiskData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const TfoRiskReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? tfoRiskData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : tfoRiskData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={5}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {tfoRiskData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const EntitiesReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? entitiesData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : entitiesData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={10}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                    label={window.innerWidth < 640 ? null : renderLabel}
                >
                    {entitiesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {window.innerWidth < 640 ? <Legend wrapperStyle={{ fontSize: "12px", padding: '0px 10px', whiteSpace: "break-spaces" }} formatter={(value, entry, index) => `${entry.payload.name + ' ' + entry.payload.value + ' %'}`} align="center" verticalAlign="bottom" layout="horizontal" /> : null}
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const MemberReportChart = () => {
        return (
            <PieChart width={window.innerWidth < 640 ? 300 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={count > 2 ? memberData.filter((element) => element.percentage > 1).map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) }) : memberData.map((element) => { return ({ name: element.name, value: element.percentage, amount: currency === 1 ? element.pygAmount : element.usdAmount }) })}
                    cx="50%"
                    cy="50%"
                    minAngle={5}
                    outerRadius={window.innerWidth < 640 ? 40 : (count > 2 ? 80 : 160)}
                    fill="#8884d8"
                >
                    {memberData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Legend formatter={(value, entry, index) => `${value}: ${entry.payload.value} %`} align={window.innerWidth < 640 ? 'center' : "left"} verticalAlign={window.innerWidth < 640 ? "bottom" : "middle"} layout={window.innerWidth < 640 ? "horizontal" : "vertical"} />
                <Tooltip content={CustomTooltip} />
            </PieChart>
        )
    }

    const DeadlineReportChart = () => {
        return (
            <BarChart layout="vertical" maxBarSize={count > 2 ? 15 : 30} width={window.innerWidth < 640 ? 300 : (count > 2 ? 400 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)} data={deadlineData.filter((element) => element.days !== "Indeterminado")}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={DeadlineCustomTooltip} />
                <Bar dataKey="days">
                    {deadlineData.filter((element) => element.days !== "Indeterminado").map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.days > 0 ? EMERALD[4] : RED[4]} />
                    ))}
                </Bar>
                <ReferenceLine x={0} stroke="#000" />
                <XAxis dataKey="days" type="number" interval="preserveStartEnd" />
                <YAxis hide={true} dataKey="idx" />
            </BarChart>
        )
    }

    const NominalRateReportChart = () => {
        return (
            <BarChart width={window.innerWidth < 640 ? 240 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)} maxBarSize={window.innerWidth < 640 ? 15 : (count > 2 ? 15 : 30)} data={pygNominalRateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={NominalRateCustomTooltip} />
                <Bar dataKey="percentage" minPointSize={10}>
                    {pygNominalRateData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Bar>
                <YAxis dataKey="percentage" type="number" interval="preserveStartEnd" />
                <XAxis dataKey="value" type="category" />
            </BarChart >
        )
    }

    const IntlNominalRateReportChart = () => {
        return (
            <BarChart width={window.innerWidth < 640 ? 240 : (count > 2 ? 440 : 700)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)} maxBarSize={window.innerWidth < 640 ? 15 : (count > 2 ? 15 : 30)} data={intlNominalRateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={NominalRateCustomTooltip} />
                <Bar dataKey="percentage" minPointSize={10}>
                    {intlNominalRateData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Bar>
                <YAxis dataKey="percentage" type="number" interval="preserveStartEnd" />
                <XAxis dataKey="value" type="category" />
            </BarChart >
        )
    }

    const ClientRiskReturnChart = () => {
        return (
            <ScatterChart width={window.innerWidth < 640 ? 200 : (count > 2 ? 400 : 900)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={RiskReturnCustomTooltip} />
                <YAxis type="number" dataKey={currency === 1 ? "rateGs" : "rateUsd"} />
                <XAxis type="number" dataKey="qualification" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Riesgo' }} y={currency === 1 ? average.rateGs : average.rateUsd} stroke="#000" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Retorno' }} x={average.officialQualification} stroke="#000" />
                <Scatter data={currency === 1 ? clientRiskReturnData.filter((element) => element.pygAmount > 0) : clientRiskReturnData.filter((element) => element.usdAmount > 0)} fill={BLUE[4]} >
                    <LabelList position="right" dataKey="name" style={window.innerWidth < 640 ? { fontSize: '50%' } : { fontSize: '70%' }} />
                </Scatter>
            </ScatterChart>
        )
    }

    const RiskReturnChart = () => {
        return (
            <ScatterChart width={window.innerWidth < 640 ? 200 : (count > 2 ? 400 : 900)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={RiskReturnCustomTooltip} />
                <YAxis type="number" dataKey={currency === 1 ? "rateGs" : "rateUsd"} />
                <XAxis type="number" dataKey="qualification" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Riesgo' }} y={currency === 1 ? average.rateGs : average.rateUsd} stroke="#000" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Retorno' }} x={average.officialQualification} stroke="#000" />
                <Scatter data={currency === 1 ? riskReturnData.filter((element) => element.pygAmount > 0) : riskReturnData.filter((element) => element.usdAmount > 0)} fill={AMBER[4]} >
                    <LabelList position="right" dataKey="name" style={window.innerWidth < 640 ? { fontSize: '50%' } : { fontSize: '70%' }} />
                </Scatter>
            </ScatterChart>
        )
    }

    const TfoClientRiskReturnChart = () => {
        return (
            <ScatterChart width={window.innerWidth < 640 ? 200 : (count > 2 ? 400 : 900)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={RiskReturnCustomTooltip} />
                <YAxis type="number" dataKey={currency === 1 ? "rateGs" : "rateUsd"} />
                <XAxis type="number" dataKey="qualification" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Riesgo' }} y={currency === 1 ? average.rateGs : average.rateUsd} stroke="#000" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Retorno' }} x={average.tfoQualification} stroke="#000" />
                <Scatter data={currency === 1 ? tfoClientRiskReturnData.filter((element) => element.pygAmount > 0) : tfoClientRiskReturnData.filter((element) => element.usdAmount > 0)} fill={EMERALD[4]} >
                    <LabelList position="right" dataKey="name" style={window.innerWidth < 640 ? { fontSize: '50%' } : { fontSize: '70%' }} />
                </Scatter>
            </ScatterChart>
        )
    }

    const TfoRiskReturnChart = () => {
        return (
            <ScatterChart width={window.innerWidth < 640 ? 200 : (count > 2 ? 400 : 900)} height={window.innerWidth < 640 ? 180 : (count > 2 ? 240 : 480)}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={RiskReturnCustomTooltip} />
                <YAxis type="number" dataKey={currency === 1 ? "rateGs" : "rateUsd"} />
                <XAxis type="number" dataKey="qualification" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Riesgo' }} y={currency === 1 ? average.rateGs : average.rateUsd} stroke="#000" />
                <ReferenceLine label={{ position: 'insideTopLeft', value: 'Retorno' }} x={average.tfoQualification} stroke="#000" />
                <Scatter data={currency === 1 ? tfoRiskReturnData.filter((element) => element.pygAmount > 0) : tfoRiskReturnData.filter((element) => element.usdAmount > 0)} fill={PURPLE[4]} >
                    <LabelList position="right" dataKey="name" style={window.innerWidth < 640 ? { fontSize: '50%' } : { fontSize: '70%' }} />
                </Scatter>
            </ScatterChart>
        )
    }

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Header current="clients" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>
                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col px-2 sm:px-24 space-y-2 w-full">
                            <Breadcrumbs pages={pages} />
                            <div>
                                <h1 className="font-bold text-3xl ml-4">Reportes - {client.name}</h1>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-4 sm:flex-row space-x-6 mr-10">
                            <button onClick={() => downloadPDF()} className="flex w-32 mt-4 shrink-0 items-center h-10 px-4 py-2 whitespace-nowrap border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900">
                                {downloadLoading ?
                                    <div className="w-4 h-4 border-b-2 border-white mx-auto rounded-full animate-spin"></div>
                                    : <p>Descargar PDF</p>
                                }
                            </button>
                            <Dropdown containerClassName="z-10 mr-10 mt-10" setValue={setCurrency} currency={currency} />
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <ul role="list" className={"divide-y w-1/3 mr-4 divide-gray-200 px-4 sm:px-28"}>
                            <li key={0} className="py-2 font-bold text-3xl">
                                Mostrar
                            </li>
                            <li key={1} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !totalHeritage) ? true : false} labelClassName={(count === 4 && !totalHeritage) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setTotalHeritage(value)} label="Total patrimonio" />
                            </li>
                            <li key={2} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !assetType) ? true : false} labelClassName={(count === 4 && !assetType) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setAssetType(value)} label="Tipo de activo" />
                            </li>
                            <li key={3} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !geographicalArea) ? true : false} labelClassName={(count === 4 && !geographicalArea) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setGeographicalArea(value)} label="Zona geográfica" />
                            </li>
                            <li key={4} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !investmentSector) ? true : false} labelClassName={(count === 4 && !investmentSector) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setInvestmentSector(value)} label="Sector de la economía" />
                            </li>
                            <li key={5} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !nationalRisk) ? true : false} labelClassName={(count === 4 && !nationalRisk) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setNationalRisk(value)} label="Riesgos (Nacionales)" />
                            </li>
                            <li key={6} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !internationalRisk) ? true : false} labelClassName={(count === 4 && !internationalRisk) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setInternationalRisk(value)} label="Riesgos (Internacionales)" />
                            </li>
                            <li key={7} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !consolidatedRisk) ? true : false} labelClassName={(count === 4 && !consolidatedRisk) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setConsolidatedRisk(value)} label="Riesgos (Consolidados)" />
                            </li>
                            <li key={8} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !tfoRisk) ? true : false} labelClassName={(count === 4 && !tfoRisk) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setTfoRisk(value)} label="Riesgos (TFO)" />
                            </li>
                            <li key={9} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !entities) ? true : false} labelClassName={(count === 4 && !entities) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setEntities(value)} label="Instituciones" />
                            </li>
                            <li key={10} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !member) ? true : false} labelClassName={(count === 4 && !member) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setMember(value)} label="Integrantes" />
                            </li>
                            <li key={11} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !deadline) ? true : false} labelClassName={(count === 4 && !deadline) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setDeadline(value)} label="Por plazos" />
                            </li>
                            <li key={12} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !nominalRate) ? true : false} labelClassName={(count === 4 && !nominalRate) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setNominalRate(value)} label="Tasas" />
                            </li>
                            <li key={13} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !intlNominalRate) ? true : false} labelClassName={(count === 4 && !intlNominalRate) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setIntlNominalRate(value)} label="Tasas Internacionales" />
                            </li>
                            <li key={14} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !clientRiskReturn) ? true : false} labelClassName={(count === 4 && !clientRiskReturn) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setClientRiskReturn(value)} label="Riesgo/Retorno Oficial - Familia -" />
                            </li>
                            <li key={15} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !riskReturn) ? true : false} labelClassName={(count === 4 && !riskReturn) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setRiskReturn(value)} label="Riesgo/Retorno Oficial - Mercado -" />
                            </li>
                            <li key={16} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !tfoClientRiskReturn) ? true : false} labelClassName={(count === 4 && !tfoRiskReturn) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setTfoClientRiskReturn(value)} label="Riesgo/Retorno TFO - Familia -" />
                            </li>
                            <li key={17} className="py-2">
                                <RightInlineCheckbox disabled={(count === 4 && !tfoRiskReturn) ? true : false} labelClassName={(count === 4 && !tfoRiskReturn) ? "font-medium text-gray-400" : null} handleChange={(name, value) => setTfoRiskReturn(value)} label="Riesgo/Retorno TFO - Mercado -" />
                            </li>
                        </ul>
                        <div className={classNames(count > 2 ? "sm:grid-cols-2" : "", "grid grid-cols-1 h-fit sm:w-full gap-6 pr-4")}>
                            {totalHeritage ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg ">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Total patrimonio</p>
                                    </div>
                                    <div className="flex justify-center mt-4 sm:pr-10">
                                        <GeneralReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {assetType ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Tipo de activo</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <AssetTypeReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {geographicalArea ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Zona geográfica</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <GeographicalAreaReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {investmentSector ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Sector de la economía</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <InvestmentSectorReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {nationalRisk ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgos (nacionales)</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <NationalRiskReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {internationalRisk ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgos (internacionales)</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <InternationalRiskReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {consolidatedRisk ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgos (Consolidados)</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <ConsolidatedRiskReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {tfoRisk ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgos (TFO)</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <TfoRiskReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {entities ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Instituciones</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <EntitiesReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {member ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Integrantes</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <MemberReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {deadline ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Plazos (en días)</p>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <DeadlineReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {nominalRate ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Tasas</p>
                                    </div>
                                    <div className="flex justify-center -ml-8 sm:ml-0 mt-4">
                                        <NominalRateReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {intlNominalRate ? <div className="bg-white z-0 overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Tasas Internacionales</p>
                                    </div>
                                    <div className="flex justify-center -ml-8 sm:ml-0 mt-4">
                                        <IntlNominalRateReportChart />
                                    </div>
                                </div>
                            </div> : null}
                            {clientRiskReturn ? <div className="bg-white z-0 h-fit overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgo/Retorno Oficial - Familia -</p>
                                    </div>
                                    <div className="flex flex-col justify-center mt-4">
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                        <ClientRiskReturnChart />
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {riskReturn ? <div className="bg-white z-0 h-fit overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgo/Retorno Oficial - Mercado -</p>
                                    </div>
                                    <div className="flex flex-col justify-center mt-4">
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                        <RiskReturnChart />
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {tfoClientRiskReturn ? <div className="bg-white z-0 h-fit overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgo/Retorno TFO - Familia -</p>
                                    </div>
                                    <div className="flex flex-col justify-center mt-4">
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>

                                        <TfoClientRiskReturnChart />
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {tfoRiskReturn ? <div className="bg-white z-0 h-fit overflow-hidden shadow rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="flex justify-between">
                                        <p className="text-xl font-semibold text-gray-700">Riesgo/Retorno TFO - Mercado -</p>
                                    </div>
                                    <div className="flex flex-col justify-center mt-4">
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MAYOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                        <TfoRiskReturnChart />
                                        <div className="flex justify-between font-semibold text-gray-700">
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MENOR RIESGO</p>
                                            </div>
                                            <div>
                                                <p>MENOR RETORNO</p>
                                                <p>MAYOR RIESGO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </>}
        </div >
    );
}
