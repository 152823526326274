import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../services/api";
import { PlusIcon, ExclamationIcon } from "@heroicons/react/solid";
import Modal from "../../components/Modal";

const columns = [
    { name: 'name', displayText: 'Usuario' },
    { name: 'govId', displayText: 'Cédula' },
    { name: 'email', displayText: 'Email' },
    { name: 'role', displayText: 'Rol' },
]

const roles = [
    "Staff",
    "Administrador"
]

const rolesOptions = [
    <option key={1} value={1} label="Staff">Staff</option>,
    <option key={2} value={2} label="Administrador">Administrador</option>
]

const rows = [
    { id: 1, name: 'Francisco Candia', govId: '4361087', email: 'francisco@girolabs.com', role: 'Administrador' }
]

export default function UsersPage() {

    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState();
    const [users, setUsers] = useState([]);
    const [idUser, setIdUser] = useState(null);
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [user, setUser] = useState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        profile: {
            govId: "",
            role: "1"
        }
    })
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        govId: '',
    });

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleGovId = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, govId: value } }))
    const handleRole = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, role: value } }))
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const fetchUser = async (id) => {
        await api.users.getOne(id).then((response) => {
            setUser(response)
            setLoading(false)
        })
    }

    const clearFields = () => {
        handleChange("firstName", "");
        handleChange("lastName", "");
        handleChange("username", "");
        handleGovId("govId", "");
        handleRole("role", "1");
        handleChange("email", "");
        setIdUser(null);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        api.users.getMulti().then((response) => {
            setUsers(response.map(({ firstName, lastName, profile, id, email }) => {
                return ({
                    name: firstName + " " + lastName, id: id, govId: profile.govId, email: email, role: roles[profile.role - 1]
                })
            })
            );
            setLoading(false);
        }
        )
    }, [])

    const fetchUsers = async () => {
        await api.users.getMulti().then((response) => {
            setUsers(response.map(({ firstName, lastName, profile, id, email }) => {
                return ({
                    name: firstName + " " + lastName, id: id, govId: profile.govId, email: email, role: roles[profile.role - 1]
                })
            })
            );
            setLoading(false);
        }
        )
    }

    const editUser = (id) => {
        setIdUser(id)
        fetchUser(id)
        setOpen(true)
    }

    const openConfirmationModal = (id) => {
        setIdUser(id)
        setOpenConfirmation(true)
    }


    const handleSubmit = () => {
        let error = false
        if (idUser) {
            api.users.update(idUser, user).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchUsers(), setOpen(false), clearFields()))
        } else {
            if (!user.firstName) {
                handleError('firstName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('firstName', '')
            }
            if (!user.lastName) {
                handleError('lastName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('lastName', '')
            }
            api.users.create(user).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : (fetchUsers(), setOpen(false), clearFields()))
        }
    };

    const content = (
        <>
            <div className="isolate -space-y-px rounded-md">
                <div className="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={user.firstName}
                        onChange={(event) => handleChange('firstName', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombres"
                    />
                </div>
                <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={user.lastName}
                        onChange={(event) => handleChange('lastName', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Apellidos"
                    />
                </div>
                <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={user.email}
                        onChange={(event) => handleChange('email', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Correo Electrónico"
                    />
                </div>
                <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={user.username}
                        onChange={(event) => handleChange('username', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="Nombre de usuario"
                    />
                </div>
                <div className="flex relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <input
                        type="text"
                        name="govId"
                        id="govId"
                        value={user.profile.govId}
                        onChange={(event) => handleGovId('govId', event.target.value)}
                        className="block w-full outline-none border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="C.I."
                    />
                </div>
                <div className="relative border border-gray-300 rounded-md rounded-t-none focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                    <select
                        type="text"
                        name="role"
                        id="role"
                        value={user.profile.role}
                        onChange={(event) => handleRole('role', event.target.value)}
                        className="shadow-sm bg-white w-full h-9 pl-2 border border-gray-300 block sm:text-sm rounded-md rounded-t-none"
                    >
                        {rolesOptions}
                    </select>
                </div>
            </div>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => handleSubmit()}
            >
                Agregar
            </button>
        </>
    )

    const deleteUser = async (id) => {
        await api.users.delete(id).catch(response => { console.log(response) }).then(() => (fetchUsers(), setOpenConfirmation(false), clearFields()))
    }

    const confirmationContent = (
        <div className="flex flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-20 w-20">
                <ExclamationIcon className="h-16 w-16 text-red-600" aria-hidden="true" />
            </div>
            <p className="text-gray-900 font-medium py-4">¿Está seguro de que desea eliminar este usuario?</p>
            <button type="button" className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:text-sm"
                onClick={() => deleteUser(idUser)}
            >
                Aceptar
            </button>
        </div>
    )

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Modal title="Crear usuario" content={content} onClose={() => (clearFields(), setOpen(false))} open={open} setOpen={setOpen} />
            <Modal title="Eliminar usuario" content={confirmationContent} onClose={() => (clearFields(), setOpenConfirmation(false))} open={openConfirmation} setOpen={setOpenConfirmation} />
            <Header current="users" />
            {loading ?
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
                :
                <>

                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2">
                            <h1 className="font-bold text-3xl ml-4">Usuarios</h1>
                        </div>
                        <button
                            onClick={() => {
                                setIdUser(null);
                                setOpen(true);
                            }}
                            type="button"
                            className="inline-flex sm:mr-20 mr-2 items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200"
                        >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            Crear nuevo usuario
                        </button>
                    </div>
                    <div className="mt-10 px-2 sm:px-20">
                        <Table editRow={editUser} deleteRow={openConfirmationModal} columns={columns} rows={users} />
                    </div>
                </>}
        </div >
    );
}
