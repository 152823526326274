import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DateInput(props) {

    const today = new Date().toISOString().substring(0, 10)

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 relative rounded-md flex flex-wrap">
                <input
                    type="date"
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    name={props.name}
                    id={props.name}
                    disabled={props.disabled}
                    defaultValue={props.defaultValue ? props.defaultValue : today}
                    className={props.className ? props.className : classNames(props.disabled ? 'bg-gray-300' : '', "shadow-sm h-8 pl-2 pr-2 w-full border border-gray-300 block sm:text-sm rounded-md")}
                />
                <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                    {props.errorMessage}
                </p>
            </div>

        </div>
    )
}