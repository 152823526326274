function numberWithSeparators(x) {
    return x.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function MoneyInput(props) {
    return (
        <div className={props.containerClassName}>
            <label htmlFor="price" className="block text-sm mt-1 font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 h-8 relative rounded-md shadow-sm border border-gray-300">
                <input
                    type="text"
                    name={props.name}
                    id="price"
                    value={props.value}
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    className="focus:ring-indigo-500 focus:border-indigo-500 h-7 w-full pl-4 pr-16 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0"
                />
                <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                    <label htmlFor="currency" className="sr-only">
                        Currency
                    </label>
                    <select
                        id="currency"
                        name="currency"
                        value={props.currency}
                        onChange={(event) => props.handleChange("currency", event.target.value)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    >
                        {props.currencyOptions}
                    </select>
                </div>
            </div>
            {props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
            {props.showValue && <p className="mt-2 text-sm font-medium text-gray-700" id={`${props.name}-value`}>
                {numberWithSeparators(props.value)}
            </p>}
        </div>
    )
}