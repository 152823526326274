import { Link, useNavigate } from 'react-router-dom';
import { CogIcon, UserCircleIcon, ChevronDownIcon, EyeIcon, ExclamationIcon } from '@heroicons/react/outline';
import { CogIcon as SolidCogIcon } from '@heroicons/react/solid';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useAuth } from '../context/authContext';
import api from '../services/api';
import Modal from './Modal';
import BurgerMenu from './BurgerMenu';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header(props) {

    const navigate = useNavigate();
    const [openLogout, setOpenLogout] = useState(false)
    const [visible, setVisible] = useState(false)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [password, setPassword] = useState("")
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [isStaff, setIsStaff] = useState(localStorage.getItem('TFOaccessToken') && userData?.profile.role === 1 ? true : false)
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem('TFOaccessToken') && userData?.profile.role === 2 ? true : false)
    const auth = useAuth();
    const navigation = (isStaff || isAdmin) ? isAdmin ? [
        { name: 'Clientes', href: '/clients', current: props.current === 'clients' ? true : false },
        { name: 'Usuarios', href: '/users', current: props.current === 'users' ? true : false },
    ] :
        [
            { name: 'Clientes', href: '/clients', current: props.current === 'clients' ? true : false },
        ] : []


    const userNavigation = [
        { name: 'Cambiar contraseña', onClick: () => setOpenChangePassword(true) },
        { name: 'Cerrar sesión', onClick: () => setOpenLogout(true) },
    ]

    const submitChangePassword = async () => {
        await api.users.changePassword(password)
            .then((response) => {
                console.log(response)
                setOpenChangePassword(false)
            })
            .catch((error) => console.log(error))
    }

    const logout = (
        <>
            < div >
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Cerrar sesión
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres salir?
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 sm:col-start-2 sm:text-sm"
                    onClick={async () => {
                        navigate('/login');
                        await auth.signOut();
                    }}
                >
                    Cerrar Sesión
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenLogout(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const changepassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Cambiar contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Nueva contraseña
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                            <input
                                type={visible ? "text" : "password"}
                                name="password"
                                onChange={(event) => setPassword(event.target.value)}
                                id="password"
                                className="block mt-1 pl-2 w-full h-8 pr-10 sm:text-sm border border-gray-300 rounded-md"
                            />
                            <button onClick={() => setVisible(!visible)} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                <EyeIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        submitChangePassword()
                    }
                >
                    Guardar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenChangePassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    return (
        <div className="min-h-full bg-white px-4 sm:px-6 lg:px-8 border-b-1 border-gray-100">
            <Modal content={changepassword} onClose={() => (setOpenChangePassword(false))} open={openChangePassword} setOpen={setOpenChangePassword} />
            <Modal content={logout} open={openLogout} onClose={() => (setOpenLogout(false))} setOpen={setOpenLogout} />
            <div className="flex items-center sm:ml-20 justify-between h-16">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img
                            className="h-8 w-full"
                            src="/logo.png"
                            alt="TFO"
                        />
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-5 flex items-baseline space-x-4">
                            {navigation.map((item) => (
                                <button
                                    key={item.name}
                                    onClick={() => navigate(item.href)}
                                    className={classNames(
                                        item.current
                                            ? 'border-blue-600'
                                            : 'text-gray-600  border-transparent hover:border-blue-600',
                                        'px-3 py-2 text-md border-b-2 font-semibold'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    {(isStaff || isAdmin) ? <div className='z-30'>
                        <BurgerMenu />
                    </div> : null}
                </div>
                <div className='flex space-x-8 sm:mr-12 '>
                    {(isStaff || isAdmin) ? <Link to={`/configurations`} key={0} className="font-semibold text-blue-700 hover:text-blue-800">
                        {props.current === 'configurations' ?
                            <SolidCogIcon className="text-blue-800 h-8 w-8" aria-hidden="true" />
                            : <CogIcon className="text-gray-400 h-8 w-8 hover:text-blue-800" aria-hidden="true" />
                        }
                    </Link> : null}
                    <Menu as="div" className="ml-3 z-30 relative">
                        <div>
                            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full">
                                <div className='flex'>
                                    <UserCircleIcon className='text-gray-400 h-8 w-8' />
                                    <ChevronDownIcon className='text-gray-400 h-4 mt-2 w-4' />
                                </div>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <button
                                                onClick={item.onClick}
                                                className={classNames(
                                                    active ? 'bg-gray-200' : '',
                                                    'block px-4 py-2 text-sm w-48 text-gray-700'
                                                )}
                                            >
                                                {item.name}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
}